import { Injectable } from "@angular/core";
import { environment } from "./../../../environments/environment";
import { BehaviorSubject } from "rxjs";
import { WhiteLabelSetupConfigurationFactory, IWhiteLabelSetupConfigurationApiResponse } from "../../factories/wl-config-data-factory";
import { WhiteLabelSetupConfiguration } from "../../data-models/wl-account-setup/wl-config-data.model";
import { Db3DApiBackendClient } from "../api/db3d-api-backend-client.service";

export type ShowPrices = {
  show_prices: boolean;
}

@Injectable({
  providedIn: "root"
})
export class WhiteLabelConfigurationService {

  public serverErrorMsg: BehaviorSubject<string> = new BehaviorSubject("");
  private readonly serverErrorMsgText = "Unsuccessful image upload. Wait a moment and try again.";
  private readonly showPricesErrorMsg = "Something went wrong on our side. Please try again later";
  private readonly updatePdfErrorMsg = "PDF template id is invalid";
  public static readonly NO_LOGO_URL_FROM_API_MSG = "no-url";
  public static readonly NO_PDF_TEMPLATE_FROM_API_MSG = "no-pdf-template";

  public pricesErrorMsg: BehaviorSubject<string> = new BehaviorSubject("");
  public pdfTemplateErrorMsg: BehaviorSubject<string> = new BehaviorSubject("");
  public whiteLabelSetupConfiguration: BehaviorSubject<WhiteLabelSetupConfiguration> = new BehaviorSubject(
    new WhiteLabelSetupConfiguration(
      "",
      "",
      "",
      false,
      "",
      false,
      "",
      false,
      "",
      "",
      false
    ));
  private wlSetupConfigurationFactory: WhiteLabelSetupConfigurationFactory = new WhiteLabelSetupConfigurationFactory();

  constructor(
    private db3DApiBackendClient: Db3DApiBackendClient
  ) {}

  public getWhiteLabelSetupConfiguration(whitelabelSlug: string): void {

    this.db3DApiBackendClient.getWhiteLabelSetupConfiguration(environment.db3dBackendDomain, whitelabelSlug).subscribe({
      next: (apiResponse: IWhiteLabelSetupConfigurationApiResponse) => {
        apiResponse.pdf_template = this.validatePdfTemplate(apiResponse.pdf_template);
        this.whiteLabelSetupConfiguration.next(this.wlSetupConfigurationFactory.createFromBackendApi(apiResponse));
      },
      error: (_error) => {
        this.whiteLabelSetupConfiguration.next({
          ...this.whiteLabelSetupConfiguration.value,
          logo: WhiteLabelConfigurationService.NO_LOGO_URL_FROM_API_MSG,
          pdfTemplate: WhiteLabelConfigurationService.NO_PDF_TEMPLATE_FROM_API_MSG
        });
        this.pricesErrorMsg.next(this.showPricesErrorMsg);
      }
    });
  }

  public updateWhiteLabelLogo(whitelabelSlug: string, file: File): void {
    const newName: string = file.lastModified + file.name;
    const newFile: File = new File([file], newName);

    const formData:FormData = new FormData();
    formData.append("logo", newFile, newName);

    this.db3DApiBackendClient.updateWhiteLabelLogo(environment.db3dBackendDomain, whitelabelSlug, formData).subscribe({
      next: (apiResponse: IWhiteLabelSetupConfigurationApiResponse) => {
        this.whiteLabelSetupConfiguration.next(this.wlSetupConfigurationFactory.createFromBackendApi(apiResponse));
      },
      error: (_error) => {
        this.serverErrorMsg.next(this.serverErrorMsgText);
      }
    });
  }

  public updateShowPrices(whitelabelSlug: string, payload: ShowPrices): void {
    this.db3DApiBackendClient.updateShowPrices(environment.db3dBackendDomain, whitelabelSlug, payload).subscribe({
      next: (apiResponse: IWhiteLabelSetupConfigurationApiResponse) => {
        this.whiteLabelSetupConfiguration.next(this.wlSetupConfigurationFactory.createFromBackendApi(apiResponse));
      },
      error: (_error) => {
        this.pricesErrorMsg.next(this.showPricesErrorMsg);
      }
    });
  }

  public updatePDFTemplate(whitelabelSlug: string, value: string): void {

    const payload: { pdf_template: string } = {
      "pdf_template": value};

    this.db3DApiBackendClient.updatePDFTemplate(environment.db3dBackendDomain, whitelabelSlug, payload).subscribe({
      next: (apiResponse: IWhiteLabelSetupConfigurationApiResponse) => {
        this.whiteLabelSetupConfiguration.next(this.wlSetupConfigurationFactory.createFromBackendApi(apiResponse));
        this.pdfTemplateErrorMsg.next("");
      },
      error:(_error: any ) => {
        this.pdfTemplateErrorMsg.next(this.updatePdfErrorMsg);
      }
    });
  }

  private validatePdfTemplate(templatePDF: string): string {
    if(templatePDF === "") templatePDF = "DB3D";
    return templatePDF;
  }
}
