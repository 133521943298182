import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Db3DApiBackendClient } from "src/app/services/api/db3d-api-backend-client.service";
import { environment } from "src/environments/environment";
import { UsersDetailsFactory } from "src/app/factories/user-details-factory";
import { UserDetails } from "src/app/data-models/manage-users/user-details.model";
import { UsersDataFetchMessages } from "src/app/data-models/manage-users/users-data-fetch-messages";
import { NavigationService } from "src/app/services/NavigationService.service";


@Component({
  selector: "app-user-details",
  templateUrl: "./user-details.component.html",
  styleUrls: ["./user-details.component.scss"]
})

export class UserDetailsComponent implements OnInit {

  public environment = environment;
  public userId: string;
  public userDetail: UserDetails;
  private userDetailsFactory: UsersDetailsFactory = new UsersDetailsFactory();
  public message: string = UsersDataFetchMessages.loading;


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private db3DApiBackendClient: Db3DApiBackendClient,
    public navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.userId = this.activatedRoute.snapshot.paramMap.get("id");
    
    this.db3DApiBackendClient.getUserDetailsData(environment.db3dBackendDomain, this.userId).subscribe({
      next: (value) => {
        this.userDetail = this.userDetailsFactory.createFromBackendApi(value); 
      },
      error: (err) => {
        if(err.status === 403)
        this.router.navigate([this.navigationService.pageNotFound]);
        else
          this.message = UsersDataFetchMessages.error;
      }
    });
  }
  
  public startEditing() {
    this.router.navigate([this.navigationService.adminUsersWithIdEdit(this.userId)], {queryParams: this.activatedRoute.snapshot.queryParams});
  }

  public backToAdminView() {
    this.router.navigate([this.navigationService.adminUsers], {queryParams: this.activatedRoute.snapshot.queryParams});
  }
}
