import { IUserDetailsApiResponse } from "../factories/user-details-factory";

export class ProductDesign {

  constructor(
    public id: string,
    public name: string,
    public categories: string,
    public brand_name: string,
    public customConfigurationId: string,
    public productId: number,
    public productName: string,
    public thumbnailUrl: string,
    public productVersion: string,
    public productVersionName: string,
    public web360: boolean,
    public pim_tenant_id: string,
    public pim_context: string,
    public saveAndRenderStrategy?: string,
    public owner?: IUserDetailsApiResponse
  ) {}
}
