/* eslint-disable arrow-body-style */
/* eslint-disable curly */
import { Component, OnInit, HostListener, OnDestroy } from "@angular/core";
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from "@angular/router";
import { AuthService } from "./services/auth.service";
import { GlobalSettingsService } from "./services/settings/global-settings.service";
import { filter, map, mergeMap } from "rxjs/operators";
import { UserDetailsService } from "./services/user-details.service";
import { GoogleTagManagerHandlerService } from "./services/google-tag-manager.service";
import { MyModelsService } from "./services/my-models-service";
import { User, UserService } from "./services/authentication/user.service";
import { Title } from "@angular/platform-browser";
import { IntiaroAnalyticsClient } from "./services/analytics/intiaro-analytics.service";
import { NgxHotjarService } from "ngx-hotjar";
import { ColorSchemeService } from "./services/colors-scheme.service";
import { WhiteLabelConfigurationService } from "./services/white-labels/white-label-configuration.service";
import { WhiteLabelContextService } from "./services/white-labels/white-label-context.service";
// eslint-disable-next-line no-console
console.log("rollback test");

declare var $: any;
declare var IntiaroEmbed: any;
declare var IntiaroSystemInfo: any;
declare var IntiaroAnalyticsManager: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit, OnDestroy {

  public loaded = false;
  private numberOfContentsBeeingLoaded: number = 0;
  private pageTitle: string;
  private currentUser: User = new User();

  // Callbacks
  private onUnityAnalyticsEventCallbackHandle: any;

  constructor(
    public settings: GlobalSettingsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    // private abTestsScopeService: AbTestsScopeService,
    private googleTagManagerHandlerService: GoogleTagManagerHandlerService,
    private userDetailsService: UserDetailsService,
    private titleService: Title,
    private myModelsService: MyModelsService,
    private intiaroAnalyticsClient: IntiaroAnalyticsClient,
    private userService: UserService,
    private hjService: NgxHotjarService,
    private colorSchemeService: ColorSchemeService,
    private whiteLabelConfigurationService: WhiteLabelConfigurationService,
    private whiteLabelContextService: WhiteLabelContextService
  ) {
    const analyticsSettings = {
      analyticsDomain: this.settings.analyticsUrl,
      testBuild: this.settings.testBuild
    };

    new IntiaroSystemInfo();
    new IntiaroAnalyticsManager(analyticsSettings);
    this.intiaroAnalyticsClient.sendEvent(
      "designerBoard3DLaunched",
      // {AbTests: this.abTestsScopeService.currentVariants}
    );

    this.initializeCallbackFunctions();
    this.googleTagManagerHandlerService.init();
    this.subscribeToRouteData();

    this.router.events.subscribe((ev) => {

      if (ev instanceof NavigationStart) {
        this.navigationStart();
      }

      if (ev instanceof NavigationEnd) {
        this.intiaroAnalyticsClient.sendEvent(
          "routeChanged",
          {Url: ev.urlAfterRedirects}
        );
        this.sendPageViewEvents(ev);
        this.identifyHotjarUser();
      }
    });
  }

  @HostListener("window:popstate", ["$event"])
  onPopState(_event: Event) {
    this.numberOfContentsBeeingLoaded = 0;
  }

  ngOnInit(): void {
    this.clearIndexedDB();
    this.authService.autoLogin();
    this.subscribeToLoggedIn();
    this.userDetailsService.initialize();
    this.colorSchemeService.loadColorChemeFromApi();
    this.initializeWhiteLabelContext();
  }

  private initializeWhiteLabelContext(): void {
    const whiteLabelSlug: string = this.whiteLabelContextService.getCurrentWhiteLabelSlug();
    if (whiteLabelSlug)
      this.whiteLabelConfigurationService.getWhiteLabelSetupConfiguration(whiteLabelSlug);
  }

  private identifyHotjarUser(): void {
    const currenUser = this.userService.getCurrentUser();

    if (currenUser?.uuid) {
      this.hjService.hj("identify", currenUser.uuid, {
        status: currenUser.accountStatus
      });
    }
  }

  private clearIndexedDB(): void {
    indexedDB.deleteDatabase("UnityCache");
    indexedDB.deleteDatabase("/idbfs");
  }

  private initializeCallbackFunctions(): void {
    this.onUnityAnalyticsEventCallbackHandle = (event: any) => { this.sendGoogleAnalyticsEvent(event); };
  }

  subscribeToRouteData() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap((route) => route.data))
      .subscribe(
        (event) => { this.pageTitle = event.title; }
      );
  }

  private subscribeToLoggedIn(): void {
    this.authService.loggedIn.subscribe((value) => {
      if (value) {
        this.myModelsService.initialize();
      }
      this.onLoginStatusChanged(value);
    });

  }

  private onLoginStatusChanged(isLoggedIn: boolean): void {
    if (isLoggedIn === null) return;

    if (isLoggedIn) {
      this.currentUser = this.userService.getCurrentUser();
      this.googleTagManagerHandlerService.sendEvent("login");
    } else {
      this.googleTagManagerHandlerService.sendEvent("logout");
    }
  }

  private navigationStart(): void {
    window.scrollTo(0, 0);
  }

  private sendPageViewEvents(event: any): void {
    if (this.pageTitle === "Product List ") {
      this.pageTitle = this.getProductListPageTitle(event.urlAfterRedirects);
    }
    this.titleService.setTitle("Db3d - " + this.pageTitle);
    this.googleTagManagerHandlerService.sendEvent("page_view", {page_title: this.pageTitle, page_path: event.urlAfterRedirects});
  }

  private sendGoogleAnalyticsEvent(event: any): void {
    this.googleTagManagerHandlerService.sendUnityEvent(event);
  }

  private getProductListPageTitle(url: string): string {
    const list = url.includes("type=custom") ? "My Designs " : "Product List ";
    const details = url.includes("id=") ? "Details" : "";
    const pageTitle: string = list + details;
    return pageTitle;
  }

  ngOnDestroy(): void {
    IntiaroEmbed.unsubscribeFomUnityAnalytics(this.onUnityAnalyticsEventCallbackHandle);
  }
}
