import { Injectable } from "@angular/core";
import { Db3DApiBackendClient } from "./api/db3d-api-backend-client.service";
import { environment } from "./../../environments/environment";
import { Observable } from "rxjs";

export enum validationErrMessages {
  noFile = "File is missing.",
  invalidFileExtensions = "Invalid file extension (use only .jpg or .png).",
  tooBigDimensions = "Image dimensions are too big (max 150px x 150px).",
  uploadError = "File upload error."
}

@Injectable()
export class AvatarService {

  public fileSource: string;
  private readonly avatarRequirements = {
    extensions: ["jpg", "png"],
    widthLimit: 150,
    heightLimit: 150,
  };

  constructor(
    private db3DApiBackendClient: Db3DApiBackendClient,
  ) {}

  public async validateFile(newImage: File): Promise<{ valid: boolean; error?: string; }> {
    if(!newImage) return {valid: false, error: validationErrMessages.noFile};

    const extensionCheckingResult = this.checkFileExtension(newImage);
    if(!extensionCheckingResult) return {valid: false, error: validationErrMessages.invalidFileExtensions};

    const dimensionsCheckingResult = await this.checkFileDimensions(newImage);
    if(!dimensionsCheckingResult) return {valid: false, error: validationErrMessages.tooBigDimensions};

    return {valid: true};
  }

  private checkFileExtension(file: File): boolean {
    const fileName = file.name.toLowerCase();
    const fileExtension: string = fileName.split(".").pop();

    return Boolean(this.avatarRequirements.extensions.includes(fileExtension));
  }

  private async checkFileDimensions(file: File): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      this.fileSource = image.src;
      image.onload = () => {
        if (image.width > this.avatarRequirements.heightLimit || image.height > this.avatarRequirements.widthLimit) {
          resolve(false);
        } else {
          resolve(true);
        }
      };

      image.onerror = () => {
        reject(new Error(validationErrMessages.uploadError));
      };
    });
  }

  public updateAvatar(file: File, userId: number, otherUserData?: {[key: string]: string} ): Observable<any> {
    const newName: string = file.lastModified + "_" + file.name;
    const newFile: File = new File([file], newName);

    const formData:FormData = new FormData();
    formData.append("profile_image", newFile, newName);

    if(otherUserData) {
      for(const [key, value] of Object.entries(otherUserData)) formData.append(key, value);
    }

    return this.db3DApiBackendClient.updateUserDetails(environment.db3dBackendDomain, userId, formData);
  }
}