import { Component, OnInit } from "@angular/core";
import { UserDetails } from "src/app/data-models/manage-users/user-details.model";
import { IUserDetailsApiResponse, UsersDetailsFactory } from "src/app/factories/user-details-factory";
import { Db3DApiBackendClient } from "src/app/services/api/db3d-api-backend-client.service";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";
import { UserDetailsService } from "src/app/services/user-details.service";

export enum requestErrorsMessages {
  general = "Something went wrong while reaching the server. If this error persists, please contact support.",
  err400 = "Invalid page specified.",
  err403 = "You don't have permission to access this resource.",
  err404 = "Http failure response.",
  err500 = "Internal server error.",
}

@Component({
  selector: "app-my-profile-abstract",
  template: ""
})

export class MyProfileAbstractComponent implements OnInit {

  public userDetails: UserDetails;
  public userDetailsFactory: UsersDetailsFactory = new UsersDetailsFactory();
  public wlSlug: string;
  public userName: string;
  public userPhoneNumber: string;
  public profileImagePath: string | null;

  constructor(
    public db3DApiBackendClient: Db3DApiBackendClient,
    private wlConfigService: WhiteLabelConfigurationService,
    public userDetailsService: UserDetailsService,
  ) {}

  ngOnInit(): void {
    this.wlConfigService.whiteLabelSetupConfiguration?.subscribe((value) => {
      this.wlSlug = value.slug;
    });

    this.userDetailsService.currentUserDetails.subscribe({
      next: (value: IUserDetailsApiResponse) => {
        if(value) {
          this.userDetails = this.userDetailsFactory.createFromBackendApi(value);
          this.userName = this.userDetails.name;
          this.userPhoneNumber = this.userDetails.phoneNumber;
          this.profileImagePath = this.userDetails.profileImagePath;  
        }
      }
    });
  }
}
