/* eslint-disable arrow-body-style */

import { Injectable } from "@angular/core";
import { Observable, Subject} from "rxjs";
import { Db3DApiBackendClient } from "../api/db3d-api-backend-client.service";
import { ProductDesign } from "../../data-models/product-design";
import { ProductCategory } from "../../data-models/types";
import { GoogleTagManagerHandlerService } from "../google-tag-manager.service";
import { IntiaroAnalyticsClient } from "../analytics/intiaro-analytics.service";
import { ProductConfig } from "src/app/data-models/product-config";

declare var hj: any;

export interface ProductDesignHttpBody {
    configuration: any,
    brand_name: string,
    product_image_url: string,
    name: string,
    support_360_configurator: boolean,
    product_id: number,
    product_name: string,
    program_name: string,
    product_categories:  Array<string>,
    pim_tenant_id: string,
    pim_context?: string,
    custom_configuration_id: string,
    save_and_render_strategy: string
}

export interface ProductDesignPayload {
    productDetails: ProductDesign | ProductConfig,
    configuration: any,
    customConfigurationId: any,
    productImageUrl: string,
    designName: string,
    productId: number
    productName: string;
    pimContext?: string
}

@Injectable()
export class ProductDesignSaverService {

  private readonly saveMyDesignEventName: string = "designSave";
  private readonly overwriteMyDesignEventName: string = "designOverwrite";

  constructor(
    private db3DApiBackendClient: Db3DApiBackendClient,
    private googleTagManagerHandlerService: GoogleTagManagerHandlerService,
    private intiaroAnalyticsClient: IntiaroAnalyticsClient,
  ) {}

  private buildCategoriesArray(categories: ProductCategory[] | string): Array<string> {
    return typeof categories === "string"
      ? categories.split(", ")
      : categories.map((category) => category.name);
  }

  private createHttpBody({
    productDetails,
    configuration, 
    customConfigurationId, 
    productImageUrl, 
    designName,
    productId,
    productName,
    pimContext
  }:ProductDesignPayload

  ): ProductDesignHttpBody {
    const obj: ProductDesignHttpBody = {
      configuration: configuration,
      brand_name: productDetails.brand_name,
      product_image_url: productImageUrl,
      name: designName,
      support_360_configurator: true,
      product_id: productId,
      product_name: productName,
      program_name: productDetails.productVersionName,
      product_categories: this.buildCategoriesArray(productDetails.categories),
      pim_tenant_id: productDetails.pim_tenant_id,
      custom_configuration_id: customConfigurationId,
      save_and_render_strategy: productDetails.saveAndRenderStrategy
    };
    if (pimContext) obj.pim_context = pimContext;
    return obj;
  }

  public saveMyDesign(
    successCallback: Function, failCallback:Function,
    domain: string,
    payload: ProductDesignPayload
  ): Observable<any> {
    
    const httpBody: ProductDesignHttpBody =  this.createHttpBody(payload);
    
    const response = this.db3DApiBackendClient.getProductDesignSave(domain, httpBody);
    response.subscribe({
      next:(value) => {
        successCallback(value);
        this.sendAnalyticsData(this.saveMyDesignEventName, httpBody, "success", payload.productDetails);
        hj("tagRecording", ["save_design"]);
      },
      error:(errorResponse) => {
        failCallback(errorResponse);
        this.sendAnalyticsData(this.saveMyDesignEventName, httpBody, "fail", payload.productDetails, errorResponse.status);
      }
    });
    return response;
  }

  public overwriteExistingDesign(
    successCallback: Function, failCallback:Function,
    domain: string, 
    payload: ProductDesignPayload,
    designId: string
  ): Observable<any> {

    const httpBody: ProductDesignHttpBody =  this.createHttpBody(payload);
    
    const response = this.db3DApiBackendClient.getOverwriteExistingDesign(domain, designId, httpBody);
    response.subscribe({
      next:(value) => {
        successCallback(value);
        this.sendAnalyticsData(this.overwriteMyDesignEventName, httpBody, "success", payload.productDetails);
      },
      error:(errorResponse) => {   
        failCallback(errorResponse);       
        this.sendAnalyticsData(this.overwriteMyDesignEventName, httpBody, "fail", payload.productDetails, errorResponse.status);
      }
    });

    return response;
  }

  private sendAnalyticsData(eventName: string, designData: ProductDesignHttpBody, status: "success" | "fail", productDetails: ProductDesign | ProductConfig, responseCode = null): void {
    const analyticsEventContext: any = productDetails;
    analyticsEventContext.status = status;
    if (responseCode) 
      analyticsEventContext.response_code = responseCode;

    this.googleTagManagerHandlerService.sendEvent(eventName, analyticsEventContext);
    this.intiaroAnalyticsClient.sendEvent(
      eventName,
      { Status: status, ResponseCode: responseCode, Value: designData}
    );
  }
}
