
import { Injectable } from "@angular/core";
import { Designer } from "../data-models/designer-data.model";

@Injectable({
  providedIn: "root"
})
export class RegistrationFlowService {

  private tempUser: Designer;
  public preapprovalText: string;

  constructor() {
    this.tempUser = new Designer();
  }

  getTempUser() {
    return this.tempUser;
  }

  reset() {
    this.tempUser = new Designer();
    this.preapprovalText = null;
  }
}
