// How to use popup:
// https://intiaro.atlassian.net/wiki/spaces/IDT/pages/640221185/Generic+popup

import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject} from "rxjs";

export interface IPopupContent {
  title?: string,
  text?: string[] | string,
  closeButton?: {
    text: string,
    callback: () => any
  },
  htmlContent?: any,
  hideCloseButton?: boolean,
  buttons?: any[],
  secondaryButtons?: any[],
  ngDynamicComponent?:{
    component: any,
    inputs?:{}
  };
}
@Injectable()
export class PopupService {

  public popupContent = new BehaviorSubject<any>(null);
  public hidePopupEvent = new Subject();
  
  private setBodyData(options?: object): object {
    let content: IPopupContent = {
      title: "",
      text: [""],
      closeButton: {
        text: "X",
        callback: () => { }
      },
      htmlContent: null,
      hideCloseButton: false,
      buttons: [],
      secondaryButtons: []
    };

    if (options) {

      content = {...content, ...options};

      if (content.closeButton.text === undefined) 
        content.closeButton.text = "X";
      
      if (content.closeButton.callback === undefined) 
        content.closeButton.callback = () => { };
      
    }

    return content;
  }

  public showPopup(options?: any) {
    const content = this.setBodyData(options);
    this.popupContent.next(content);
  }

  public getDefaultContent() {
    return this.setBodyData();
  }

  public hidePopup() {
    this.hidePopupEvent.next(true);
    this.popupContent.next(null);
  }
}
