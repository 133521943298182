import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NavigationService } from "src/app/services/NavigationService.service";

@Component({
  selector: "app-account-status",
  templateUrl: "./account-status.component.html",
  styleUrls: ["./account-status.component.css"]
})
export class AccountStatusComponent {

  constructor(
    private router: Router,
    public navigationService: NavigationService
  ) { }

  public goToLoginPage(): void {
    this.router.navigate([this.navigationService.login]);
  }

}
