import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';


@Component({
    selector: 'app-full-functionality-dialog',
    templateUrl: './full-functionality-dialog.component.html',
    styleUrls: ['./full-functionality-dialog.component.scss']
})
export class FullFunctionalityDialogComponent implements OnInit {
    @ViewChild('sendInvitationForm') sendInvitationForm: NgForm;
    public email: string;
    public formSubmitted = false;

    private hubspotFormId = '10dae98a-43f9-42f8-8c24-eb891d9f6c09';
    private hubspotAccountId = 9267294;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { productDesignUUID: string },
        private dialogRef: MatDialogRef<FullFunctionalityDialogComponent>,
        private httpClient: HttpClient
    ) { }

    ngOnInit(): void { }

    validateForm(formData) {
        formData.form.markAsDirty();
        formData.form.markAsTouched();

        let isValid = true;
        if (!formData.value.email) {
            formData.form.controls.email.setErrors({incorrect: true});
            formData.form.controls.email.markAsTouched();
            isValid = false;
        }
        return isValid;
    }

    submitForm(formData) {
        const formValid = this.validateForm(formData);
        if (!formValid) {
            return;
        }
        const hubspotFormEndpoint = `https://api.hsforms.com/submissions/v3/integration/submit/${this.hubspotAccountId}/${this.hubspotFormId}`;
        const payload = {
            fields: [
                {
                    objectTypeId: '0-1',
                    name: 'email',
                    value: formData.value.email
                },
            ],
            context: {
                pageUri: `app.db3d.com/product-design-demo/${this.data.productDesignUUID}`,
                pageName: 'Producct design demo'
            },
        };
        this.httpClient.post(hubspotFormEndpoint, payload).subscribe(
            (response) => {
                this.formSubmitted = true;
            },
            (error) => {
                this.formSubmitted = true;
            }
        );
    }

    closePopup() {
        this.dialogRef.close();
    }
}
