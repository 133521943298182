import { Component, OnDestroy, OnInit } from "@angular/core";
import { UserService } from "src/app/services/authentication/user.service";
import { MyModelsService } from "src/app/services/my-models-service";

@Component({
  selector: "my-models",
  templateUrl: "./my-models.component.html",
  styleUrls: ["./my-models.component.scss"],
})
export class MyModelsComponent implements OnInit, OnDestroy {
  private subscriptions: Array<any> = [];
  public models: Array<any> = [];
  public page: number = 0;
  public pagesTotal: number;
  public loadingItems: boolean = true;

  constructor(
    private myModelsService: MyModelsService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.subscribeToMyModels();
  }

  subscribeToMyModels(): void {
    this.subscriptions.push(
      this.myModelsService.modelsItems.subscribe((models: any) => {
        this.models = models;
      })
    );

    this.subscriptions.push(
      this.myModelsService.pagesTotal.subscribe((value: number) => {
        this.pagesTotal = value;
      })
    );

    this.subscriptions.push(
      this.myModelsService.loading.subscribe((value: boolean) => {
        this.loadingItems = value;
      })
    );

    this.subscriptions.push(
      this.myModelsService.hasNotificationsDisplayed.subscribe(
        (value: number) => {
          if (value > 0) this.myModelsService.disableNewLabelForViewedMyModelsItems();
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((e) => e.unsubscribe());
  }
}
