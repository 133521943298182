import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-two-states-button',
  templateUrl: './two-states-button.component.html',
  styleUrls: ['./two-states-button.component.scss']
})
export class TwoStatesButtonComponent implements OnInit {

  @Input() opened = false;
  @Input() labelClosed = '';
  @Input() labelOpened: string;
  public label: string;

  @Input() animation = false;

  @Input() icon: string;
  @Input() public useDefaultIcon = 'true';

  constructor() {
  }

  ngOnInit(): void {
    this.setLabel();
  }

  onClick() {
    this.opened = !this.opened;

    this.setLabel();
  }

  setLabel() {
    if (this.opened && this.labelOpened) {
      this.label = this.labelOpened;
    } else {
      this.label = this.labelClosed;
    }
  }

}
