import { Component, OnInit } from "@angular/core";
import { ColorSchemeService, Colors } from "src/app/services/colors-scheme.service";

@Component({
  selector: "app-adjust-color-scheme",
  templateUrl: "./adjust-color-scheme.component.html",
  styleUrls: ["./adjust-color-scheme.component.scss"]
})

export class AdjustColorSchemeComponent implements OnInit {

  public colorsTemplate: Colors;

  // eslint-disable-next-line no-unused-vars
  constructor(private colorSchemeService: ColorSchemeService) {}

  ngOnInit(): void {
    this.colorSchemeService.colorsSubject.subscribe({
      next: (colors: Colors) => this.colorsTemplate = colors
    });
  }

  public changeColor(value:any, key:any) {
    this.colorSchemeService.updateColorScheme(key, value);
  }
}