import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

function fullNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      const words = control.value.split(' ');
      let valid = true;

      words.forEach(word => {
        if (word.length > 100) {
          valid = false;
        }
      });

      return valid ? null : { invalidFullName: true };
    };
}

@Directive({
  selector: "[appFullNameValidator]",
  providers: [{ provide: NG_VALIDATORS, useExisting: CheckFullNameDirective, multi: true }]
})
export class CheckFullNameDirective implements Validator {
  private valFn;

  constructor() {
    this.valFn = fullNameValidator();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.valFn(c);
  }

}

