import { Component } from "@angular/core";

@Component({
  selector: "app-product-action-bar",
  templateUrl: "./product-action-bar.component.html",
  styleUrls: ["./product-action-bar.component.scss"]
})
export class ProductActionBarComponent {

  constructor() {}

}






