import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { User, UserService } from './user.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    public userService: UserService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.headers.get('Require-Auth') === 'false') {
      request.headers.delete('Require-Auth');
      return next.handle(request);
    }

    const user: User = this.userService.getCurrentUser();
    let token: string = null;

    if (user !== null)
    {
      token = user.token;
    }

    const activeUrl = this.router.routerState.snapshot.url;
    if (activeUrl.includes('/product-design-demo/')) {
        request = request.clone({
            setHeaders: {
                Authorization: `JWT ${environment.productDesignDemoPageToken}`
            }
        });

        return next.handle(request);
    }

    if (user?.db3dToken) {

      const tokenString = request.url.includes(environment.db3dBackendDomain) ?
        'JWT ' + user.db3dToken : 'JWT ' + token;
      // https://wiki.intiaro.com/index.php?title=Api/token-auth
      request = request.clone({
        setHeaders: {
          // Authorization: 'JWT ' + token
          Authorization: tokenString
        }
      });
    }

    return next.handle(request);
  }
}
