/* eslint-disable curly */
import {Injectable} from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

export enum Currency {
  USD = "USD",
  CAD = "CAD"
}
export interface ICurrencyService {
  readonly availableCurrencies: Currency[];
  currency: Currency;
}

@Injectable()
export class CurrencyService implements ICurrencyService {

  static currencyInfoMap: Map<Currency, string> = new Map([
    [Currency.USD, "United States Dolar"],
    [Currency.CAD, "Canadian Dolar"]
  ]);

  private regionCurrencyMap: Map<string, Currency> = new Map(
    [["en-CA", Currency.CAD], ["fr-CA", Currency.CAD], ["en-US", Currency.USD]]);
    
  private _availableCurrencies: Currency[] = [Currency.USD, Currency.CAD];
  private defaultCurrency: Currency = Currency.USD;

  private readonly currencyLocalStorageKey: string = "db3d-currency-symbol";

  private _currency: Currency = localStorage.getItem(this.currencyLocalStorageKey) as Currency;

  public currencyChange: BehaviorSubject<Currency> = new BehaviorSubject(this._currency);

  get availableCurrencies(): Currency[] {
    return this._availableCurrencies;
  }

  get currency(): Currency {
    return this._currency;
  }

  constructor() {
    if (!this._currency) {
      this.setCurrency(this.getLocalizedCurrency());
    }
  }

  public setCurrency(currency: Currency): void {
    this._currency = currency;
    this.currencyChange.next(this._currency);
    localStorage.setItem(this.currencyLocalStorageKey, currency);      
  }

  private getLocalizedCurrency(): Currency {
    return this.regionCurrencyMap.has(navigator.language) 
      ? this.regionCurrencyMap.get(navigator.language) 
      : this.defaultCurrency;
  }
}
