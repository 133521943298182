import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-toast-base",
  templateUrl: "./toast-base.component.html",
  styleUrls: ["./toast-base.component.scss"],
})

export class ToastBaseComponent {
  @Input() icon: string = "";
  @Input() message: string = "";
  @Input() backgroundColor: string = "";
  @Output() closeToastFlag = new EventEmitter<void>();

  closeToast(event): void {
    this.closeToastFlag.emit();
  }
}
