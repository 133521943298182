import {Directive, Input} from '@angular/core';
import { AbstractControl, UntypedFormGroup, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";
import {element} from 'protractor';

function validateFields(): ValidatorFn {
  return (control: AbstractControl) => {
    let isValid = false;
    if (control && control instanceof UntypedFormGroup) {
      const group = control as UntypedFormGroup;
      Object.values(group.controls).forEach(
        singleControl => {
          if (singleControl.value?.length > 0 && singleControl.status === 'VALID'){
            isValid = true;
          }
        });
    }
    if (isValid) {
      return null;
    } else {
      return { 'requireOne': 'failed' };
    }
  };
}

@Directive({
  selector: '[appRequireOneInputField]',
  providers: [{ provide: NG_VALIDATORS, useExisting: RequireOneInputFieldDirective, multi: true }]
})
export class RequireOneInputFieldDirective implements Validator {
  @Input() appRequireOneInputField = true;
  private valFn;

  constructor() {
    this.valFn = validateFields();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    if( this.appRequireOneInputField){
      return this.valFn(c);
    }
  }

}
