export class Designer {
  email: string;
  password1: string;
  password2: string;
  name: string;
  surname: string;
  country: string;
  state: string;
  type: string;
  id: string;
  company: string;
  employees: number;
  homepage: string;
  termsAccepted: boolean;
  code: string;
  phone: string;

  constructor(email = null, termsAccept = false, designerCode = null) {
    this.email = email;
    this.termsAccepted = termsAccept;
    this.code = designerCode;
  }
}
