/* eslint-disable arrow-body-style */
/* eslint-disable curly */
import { Component, Input, ChangeDetectorRef, ElementRef, ViewChild, Output, EventEmitter, AfterViewInit, OnInit} from "@angular/core";
import { Subject } from "rxjs";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";

declare var IntiaroPlayer: any;
declare var IntiaroConfigurator: any;

@Component({
  selector: "app-product-config-configurator360",
  template: "<div #element360></div>",
  styleUrls: ["./product-config-configurator360.component.scss"]
})
export class ProductConfigConfigurator360Component implements AfterViewInit, OnInit {

  @Output() configurator360ReadyEvent: EventEmitter<boolean> = new EventEmitter(); 

  @Input() brandId: string;
  @Input() configurationId: string;
  @Input() domainTemplate: string;
  @Input() showMenu:boolean = true;
  @Input() context?: string;
  @Input() saveAndRenderStrategy: string = "progressive";

  @ViewChild("element360", { read: ElementRef })
  private element360: ElementRef;
  hiddenInactiveChoices: boolean = false;

  public static readonly defaultConfiguratorId:string = "product-configurator";
  get defaultConfiguratorId():string {
    return ProductConfigConfigurator360Component.defaultConfiguratorId;
  }
  
  public isConfigurator360Loaded:Subject<boolean> = new Subject();

  constructor(
    public changeDetector: ChangeDetectorRef,
    private wlConfigurationService: WhiteLabelConfigurationService
  ) {}

  ngOnInit(): void {
    this.wlConfigurationService.whiteLabelSetupConfiguration.subscribe((wlConfig) => {
      this.hiddenInactiveChoices = !wlConfig.configuratorInactiveChoicesVisible;
    });
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    this.createIntiaroInstance();
  }

  private async createIntiaroInstance(): Promise<void> {
    await this.create360InstancetRef();
    if (this.showMenu) {
      IntiaroConfigurator.create(ProductConfigConfigurator360Component.defaultConfiguratorId).then(() => this.intiaro360Ready());
    } else {
      IntiaroPlayer.create(ProductConfigConfigurator360Component.defaultConfiguratorId).then(() => this.intiaro360Ready());
    }
  }

  private intiaro360Ready(): void {
    this.isConfigurator360Loaded.next(true);
    this.configurator360ReadyEvent.emit(true);
  }

  private async create360InstancetRef(): Promise<void> {

    this.changeDetector.detectChanges();
    this.element360.nativeElement.id = this.defaultConfiguratorId;
    this.element360.nativeElement.style = "line-height: normal;"; //temporary solution until new release of 360. (bug with wide loading bar)

    this.element360.nativeElement.setAttribute("data-customer-id", this.brandId);
    this.element360.nativeElement.setAttribute("data-product-id", this.configurationId);
    this.element360.nativeElement.setAttribute("data-domain-template", this.domainTemplate);
    this.element360.nativeElement.setAttribute("data-max-width", "1024");
    this.element360.nativeElement.setAttribute("data-max-height", "1024");
    this.element360.nativeElement.setAttribute("data-context", this.context);
    this.element360.nativeElement.setAttribute("data-save-and-render-strategy", this.saveAndRenderStrategy);
    this.element360.nativeElement.setAttribute("data-silo-shoot", "false");
    this.element360.nativeElement.setAttribute("data-hide-unavailable-choices", this.hiddenInactiveChoices.toString());
  }
}
