/* eslint-disable quotes */
import {Component, ContentChild, ElementRef } from "@angular/core";

@Component({
  selector: "app-password-input-wrapper",
  templateUrl: "./password-input-wrapper.component.html",
  styleUrls: ["./password-input-wrapper.component.scss", "../../authentication-module.scss"]
})
export class PasswordInputWrapperComponent {

  @ContentChild('passwordInput') private passwordInput: ElementRef;
  @ContentChild('passwordInput2') private passwordInput2: ElementRef;

  public isPasswordVisible: boolean = true;

  constructor() {}

  public embedIconDrivenByInputType(): string {
    if (this.isPasswordVisible) 
      return '../../../../../assets/images/icons/registration/password_show_eye_icon.svg';
    else 
      return '../../../../../assets/images/icons/registration/password_show_close_eye_icon.svg';
  }

  public togglePasswordVisible(): void {
    const input = this.passwordInput || this.passwordInput2;
    const currentType = input.nativeElement.getAttribute("type");    
    const type = currentType === "password" ? "text" : "password";
    this.isPasswordVisible = type === "password" ? true : false;
    input.nativeElement.setAttribute("TYPE", type);
  }

  public preventDefaultBehaviour(event: Event): boolean {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
}
