import {Component, OnDestroy, OnInit} from "@angular/core";
import { Subscription } from "rxjs";
import { WhiteLabelSetupConfiguration } from "src/app/data-models/wl-account-setup/wl-config-data.model";
import { FooterService } from "src/app/services/footer.service";
import { NavbarService } from "src/app/services/navbar.service";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";

@Component({
  selector: "app-authentication-module-container",
  templateUrl: "./authentication-module-container.component.html",
  styleUrls: ["./authentication-module-container.component.scss"]
})
export class AuthenticationModuleContainerComponent implements OnInit, OnDestroy {

  public backgroundImage: string = ""; 
  private whiteLabelSetupConfigurationSubscription: Subscription;
  private readonly showFeedbackButtonClassName = "feedback-button-left"; 

  constructor(
    private navbarService: NavbarService,
    private footerService: FooterService,
    private whiteLabelConfigurationService: WhiteLabelConfigurationService
  ) {}
  
  ngOnInit(): void {
    this.hideNavAndFooter();
    this.whiteLabelSetupConfigurationSubscription = this.whiteLabelConfigurationService.whiteLabelSetupConfiguration.subscribe((wlConfig: WhiteLabelSetupConfiguration) => {
      if (wlConfig.loginBackground) this.backgroundImage = wlConfig.loginBackground;
    });
    document.body.classList.add(this.showFeedbackButtonClassName);
  }

  ngOnDestroy(): void {
    this.showNavAndFooter();
    this.whiteLabelSetupConfigurationSubscription?.unsubscribe;
    document.body.classList.remove(this.showFeedbackButtonClassName);
  }

  private hideNavAndFooter(): void {
    this.navbarService.hide();
    this.footerService.hide();
  }

  private showNavAndFooter(): void {
    this.navbarService.show();
    this.footerService.show();
  }

}
