import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { LoggingService } from 'src/app/services/logging.service';
import { Model3DDownloadService } from 'src/app/services/model-3d-download.service';
import { NotificationsService } from 'src/app/services/notifications-service';
import { NotificationsPopupComponent } from '../notifications-popup/notifications-popup.component';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit, OnDestroy {

  private dialogRef;
  private dialogConfig;

  private notifications;
  public numberOfNewNotifications = 0;

  @ViewChild('notification', { static: false }) public notificationIcon: ElementRef;

  constructor(
    public dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    private loggingService: LoggingService,
    private notificationsService: NotificationsService,
    private model3DDownloadService: Model3DDownloadService
  ) { }

  ngOnInit() {
    this.notificationsService.initialize((notifications) => {this.onNotificationsInitialized(notifications); });
    this.subscribeTonotificationsService();
  }

  onNotificationsInitialized(notifications) {
    this.checkUnfinishedDownloadNotifications(notifications);
  }

  checkUnfinishedDownloadNotifications(notifications) {
    notifications.forEach(notification => {
      if (notification.type === 'download_3d_model_status') {
        if (notification.type_specific_data.status !== 'done') {

          const fileData = {
            filename: notification.type_specific_data.filename,
            thumbnail_url: notification.type_specific_data.thumbnail_url,
            mesh_format: notification.type_specific_data.mesh_format,
          };

          this.model3DDownloadService.checkIf3DModelReady(notification.uuid, notification.type_specific_data);
        }
      }
    });
  }

  subscribeTonotificationsService() {
    this.notificationsService.notifications.subscribe(
      (notifications) => {

        this.notifications = notifications;
        this.numberOfNewNotifications = this.coundNewNotifications(notifications);
        if (this.numberOfNewNotifications > 0) {
          const dialogExist = this.dialog.getDialogById('download-model-popup');
          if (!dialogExist) {
            this.openPopup();
          }
        }
      }
    );
  }

  coundNewNotifications(notifications) {
    let count = 0;

    notifications.forEach(notification => {
      if (!notification.displayed) {
        count ++;
      }
    });

    return count;
  }

  onResize(event) {

    const dialogExist = this.dialog.getDialogById('download-model-popup');
    if (!dialogExist) {
      return;
    }

    const rect: DOMRect = this.notificationIcon.nativeElement.getBoundingClientRect();
    if (rect && rect.left > 0) {
      this.dialogRef.updatePosition({ left: `${rect.left - 450}px`, top: `${rect.bottom + 2}px` });
    } else {
      this.dialogRef.close();
    }
  }

  openPopup() {
    this.loggingService.log('opening popup component');
    const rect: DOMRect = this.notificationIcon.nativeElement.getBoundingClientRect();
    if (rect.left <= 0) {
      return;
    }

    this.dialogConfig = new MatDialogConfig();

    this.dialogConfig.id = 'download-model-popup';
    this.dialogConfig.width = '500px';
    this.dialogConfig.panelClass = 'db3d-notification-popup-wrapper';
    this.dialogConfig.backdropClass = 'db3d-transparent-backdrop';
    this.dialogConfig.position = { left: `${rect.left - 450}px`, top: `${rect.bottom + 2}px` };

    this.dialogRef = this.dialog.open(NotificationsPopupComponent, this.dialogConfig);

    this.dialogRef.afterClosed().subscribe(
      () => {
        this.loggingService.log('popup has been closed');
        this.afterPopupClosed();
      }
    );

    this.dialogRef.backdropClick().subscribe(
      () => {
        this.loggingService.log('close clicked');
        this.closePopup();
        this.changeDetector.detectChanges();
      }
    );
  }

  afterPopupClosed() {
    this.dialogRef = null;
    this.disableNewLabelForViewedModels();
  }

  disableNewLabelForViewedModels() {

    if (this.numberOfNewNotifications > 0 ) {
      const displayedNotifications = [];

      this.notifications.forEach(notification => {
        if (!notification.displayed) {
          displayedNotifications.push({
            id: notification.id,
            displayed: true
          });
        }
      });

      this.notificationsService.updateNotificationsDisplayedFlag(displayedNotifications);
    }
  }

  closePopup() {
    this.loggingService.log('closing popup component');
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.closePopup();
    this.model3DDownloadService.stopAskingForPendingRequests();
    this.notificationsService.stopUpdateInterval();
  }
}
