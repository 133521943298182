import { Component, OnInit } from "@angular/core";
import { WhiteLabelSetupConfiguration } from "src/app/data-models/wl-account-setup/wl-config-data.model";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";

@Component({
  selector: "app-navbar-only-logo",
  templateUrl: "./navbar-only-logo.component.html",
  styleUrls: ["./navbar-only-logo.component.scss"]
})

export class NavbarOnlyLogoComponent implements OnInit {
  public logoUrl: string ="";

  constructor(
    private wlConfigurationService: WhiteLabelConfigurationService
  ) {}

  ngOnInit(): void {
    this.wlConfigurationService.whiteLabelSetupConfiguration.subscribe( (config: WhiteLabelSetupConfiguration) => {
      this.logoUrl = config.logo;
    });
  }
}
