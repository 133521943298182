/* eslint-disable arrow-body-style */
import { Component, Output, Input, EventEmitter, ViewChild, HostListener, ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { NavbarService } from "../../services/navbar.service";
import { FooterService } from "../../services/footer.service";
import { NgForm } from "@angular/forms";
import { ProductConfig } from "../../data-models/product-config";
import { GlobalSettingsService } from "src/app/services/settings/global-settings.service";
import { PopupService } from "src/app/services/popup.service";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { SaveButtonComponent } from "../save-button/save-button.component";
import { ProductDesignPayload, ProductDesignSaverService } from "src/app/services/product-savers/product-design-saver.service";
import { NavigationService } from "src/app/services/NavigationService.service";

declare var getIntiaroPlayer: any;

@Component({
  selector: "app-fullscreen-design-saver",
  templateUrl: "./fullscreen-design-saver.component.html",
  styleUrls: ["./fullscreen-design-saver.component.scss"]
})
export class FullscreenDesignSaverComponent implements OnInit, OnDestroy {
  public designName: string = "";
  public nameConflictDisclaimerText: string = "";
  public nameConflictDisclaimer: boolean = false;

  private configuration: any;
  public savedDesignName: string = "";

  private customConfigurationId: string;

  private readonly showPopupDeleyMs: number = 500;
  private readonly screenWidthBreakpoint: number = 650;
  public isConfigurationReady: boolean = false;

  @ViewChild("designSaverForm") designSaverForm: NgForm;
  
  @Output() backToProductView: EventEmitter<boolean> = new EventEmitter();

  @Input() private productConfig: ProductConfig;
  @Input() productConfigId: number;
  @Input() productConfiguration: ProductConfig;
  @Input() intiaro360ConfiguratorId: string;
  @Input() pimContext?: string;
  @Input() pimTenantId?: string;
  @Input() resolutionX: number = 2048;
  @Input() resolutionY: number = 2048;
  designId: any;

  @HostListener("window:resize", ["$event"])
  onResize(event: any): void {
    this.setNavbarVisibility (event.target.innerWidth);
  }

  constructor(
    private navbarService: NavbarService,
    private globalSettingsService: GlobalSettingsService,
    private router: Router,
    private popupService: PopupService,
    private footerService: FooterService,
    private saveButton: SaveButtonComponent,
    private changeDetector: ChangeDetectorRef,
    private productDesignSaverService: ProductDesignSaverService,
    public navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.setNavbarVisibility (window.innerWidth);
    this.footerService.hide();
    this.initiateConfiguration();
  }

  private productImageUrl: string;

  private async initiateConfiguration(): Promise<void> {

    const successCallback = (value: string) => {
      this.productImageUrl = value;
    };

    const failCallback = (errorResponse: any) => {
      console.error(errorResponse);
    };

    try {
      this.configuration = await this.getProductConfiguration();

      await getIntiaroPlayer(this.intiaro360ConfiguratorId).getImageUrl(
        false, 
        0, 
        successCallback, 
        failCallback, 
        "none", 
        {resolutionX: this.resolutionX, resolutionY: this.resolutionY}
      );
  
      await getIntiaroPlayer(this.intiaro360ConfiguratorId).saveConfiguration((id: string) => this.customConfigurationId = id);
  
      this.isConfigurationReady = (typeof this.productImageUrl === "string") && (typeof this.customConfigurationId === "string");
    } catch(err) {
      this.isConfigurationReady = false;
    }
  }

  ngOnDestroy(): void {
    this.navbarService.show();
    this.footerService.show();
  }

  async getProductConfiguration(): Promise<void> {
    return await getIntiaroPlayer(this.intiaro360ConfiguratorId).getModularConfiguration(true);
  }

  private hideDesignSaverForm(): void {
    this.designSaverForm.reset();
    this.backToProductView.emit(true);
  }

  private setNavbarVisibility(screenWidth: number): void {
    if(screenWidth > this.screenWidthBreakpoint) this.navbarService.show();
    else this.navbarService.hide();
  }

  private getPayload(): ProductDesignPayload {
    const obj: ProductDesignPayload = {
      productDetails: this.productConfig,
      configuration: this.configuration,
      customConfigurationId: this.customConfigurationId,
      productImageUrl: this.productImageUrl,
      designName: this.designName || this.productConfiguration.name,
      productId: this.productConfigId,
      productName: this.productConfiguration.name,
    };
    if (this.pimContext) obj.pimContext = this.pimContext;
    return obj;
  }

  public saveDesign(): void {
    this.saveButton.setDisabledAttribute(true);
    this.changeDetector.detectChanges();
    this.nameConflictDisclaimer = false;

    const successCallback = (value: any) => {
      this.savedDesignName = this.designName;
      this.showSavedSuccessfullyPopup(value.id);
      this.saveButton.setDisabledAttribute(false);
      this.designSaverForm.reset();
    };

    const failCallback = (errorResponse: any) => {
      this.saveButton.setDisabledAttribute(false);

      if (errorResponse.status === 409) {
        this.designId = errorResponse.error.my_design_id;
        this.showNameConflictPopup(errorResponse.error.my_design_id);
      }else if (errorResponse.status === 400 && errorResponse.error.configuration)
        this.showEmptySceneErrorPopup();
      else {
        this.designSaverForm.reset();
        this.showSaveErrorPopup();
      }
    };

    this.productDesignSaverService.saveMyDesign(
      successCallback, failCallback,
      this.globalSettingsService.db3dBackendDomain,
      this.getPayload()
    );

  }

  public overwriteExistingDesign(designIdToOverwrite: string): void {

    const successCallback = (value: any) => {
      this.designSaverForm.reset();
      this.showSavedSuccessfullyPopup(value.id);
      this.saveButton.setDisabledAttribute(false);
    };

    const failCallback = (errorResponse: any) => {
      this.saveButton.setDisabledAttribute(false);
      if (errorResponse.status === 400 && errorResponse.error.configuration) this.showEmptySceneErrorPopup();
      else this.showSaveErrorPopup();
    };

    this.productDesignSaverService.overwriteExistingDesign(
      successCallback, failCallback,
      environment.db3dBackendDomain,
      this.getPayload(),
      designIdToOverwrite
    );
  }

  private showSavedSuccessfullyPopup(savedDesignId: string): void {
    this.hideDesignSaverForm();
    const options = {
      title: "Success!",
      text: "Your design was saved successfully. You can see it by clicking the button below.",
      buttons: [
        {
          text: "See my design",
          callback: () => {
            this.popupService.hidePopup();
            this.router.navigate([this.navigationService.productDesign(savedDesignId)]);
          }
        }
      ]
    };
    this.showPopupWithDelay(options);
  }

  private showNameConflictPopup(designIdToOverwrite: string): void {
    const options = {
      title: "My Designs",
      text: "Design with this name already exists in your library? Would you like to overwrite it or save it as a new one with different name?",
      buttons: [
        {
          text: "Overwrite",
          callback: () => {
            this.popupService.hidePopup();
            this.overwriteExistingDesign(designIdToOverwrite);
          }
        },
        {
          text: "New",
          callback: () => {
            this.popupService.hidePopup();
            this.nameConflictDisclaimer = true;
          }
        }
      ]
    };
    this.showPopupWithDelay(options);
  }

  private showEmptySceneErrorPopup(): void {
    this.hideDesignSaverForm();
    const options = {
      title: "Saving My Design Failed",
      text: "Your configuration cannot be empty. Please select at least 1 section and confirm by clicking \"Finish building\" button.",
      buttons: [
        {
          text: "Close",
          callback: () => {
            this.popupService.hidePopup();
          }
        }
      ]
    };
    this.showPopupWithDelay(options);
  }

  private showSaveErrorPopup(popupText = "Could not save this design. Please try again later."): void {
    this.hideDesignSaverForm();
    const options = {
      title: "My Designs",
      text: popupText,
    };
    this.showPopupWithDelay(options);
  }

  private showPopupWithDelay(options: any, delayInMs?: number): void {
    setTimeout(() => {
      this.popupService.showPopup(options);
    }, delayInMs ? delayInMs : this.showPopupDeleyMs);
  }
}