import {Injectable} from "@angular/core";
import {UserService} from "../authentication/user.service";
import {ActivatedRouteSnapshot, Router, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {IGuardMixin} from "./mixins/IGuardMixin";

@Injectable()
export abstract class PermissionGuardBase {
  protected guardMixins: IGuardMixin[];

  constructor(protected userService: UserService, private router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot): boolean | Promise<boolean> | Observable<boolean> | UrlTree {

    for (const guardMixin of this.guardMixins) {
      if (!guardMixin.evaluate(route)) 
        return guardMixin.getRedirectUrlTree(this.router, route);
    }
    return true;
  }
}