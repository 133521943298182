import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GoogleTagManagerHandlerService } from 'src/app/services/google-tag-manager.service';
import { LoggingService } from 'src/app/services/logging.service';
import { NotificationsService } from 'src/app/services/notifications-service';
import { IntiaroAnalyticsClient } from '../../services/analytics/intiaro-analytics.service';

declare var hj: any;
declare var IntiaroAnalyticsManager: any;

@Component({
  selector: 'app-notifications-popup',
  templateUrl: './notifications-popup.component.html',
  styleUrls: ['./notifications-popup.component.css']
})
export class NotificationsPopupComponent implements OnInit, OnDestroy {

  private subscription;
  public notifications = [];
  public numberOfNotifications = 0;

  private download3DEventName = 'download3DModelStarted';

  constructor(
    private notificationsService: NotificationsService,
    private loggingService: LoggingService,
    private googleTagManagerHandlerService: GoogleTagManagerHandlerService,
    private dialogRef: MatDialogRef<NotificationsPopupComponent>,
    private intiaroAnalyticsClient: IntiaroAnalyticsClient
    ) {
      this.loggingService.log('popup body constructor');
    }

  ngOnInit(): void {
    this.loggingService.log('popup body init');
    this.subscription = this.notificationsService.notifications.subscribe(
      (notifications) => {
        this.notifications = notifications;
        this.numberOfNotifications =  notifications.length;
      }
    );

  }

  get3DModel(data) {
    this.googleTagManagerHandlerService.sendEvent(this.download3DEventName, data);

    this.intiaroAnalyticsClient.sendEvent(this.download3DEventName, { Value: data});
    hj('tagRecording', ['3d_download']);
    window.location.href = data.generated_model_url;
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.loggingService.log('popup body destroy');
    this.subscription.unsubscribe();
  }
}
