import { Injectable } from "@angular/core";
import { IntiaroPublicApiClient } from "./api/intiaro-public-api-client.service";
import { ModularConfig, ModularConfigFactory } from "src/app/factories/modular-configuration-factory";
import { ListPrice, Price } from "src/app/data-models/price.model";
import { PimApiClient } from "src/app/services/api/pim-api-client.service";
import { CurrencyService} from "./currencyService";
import { RegionService} from "./region.service";
import { IPriceApiResponse, PriceFactory } from "../factories/price-factory";

export interface BodyForPricing {
  contexts: string[];
  region: string;
  currency: string;
  configuration: any;
}

export enum PimConfigurationKeyMapping {
  Default = "default_configuration",
  Custom = "product_configuration"
} 

@Injectable({
  providedIn: "root",
})
export class PriceCalculatorService {

  constructor(
    private intiaroPublicApiClient: IntiaroPublicApiClient,
    private pimApiClient: PimApiClient,
    private currencyService: CurrencyService,
    private regionService: RegionService,
  ) {}

  private async getPriceList(apiResponse: Promise<Response>, configurationKey: "default_configuration" | "product_configuration", pimTenantId: string, pimContext: string): Promise<ListPrice> {
    try {
      const response = await apiResponse;
      const result = await response.json();
      const configuration = ModularConfigFactory.createFromBackendApi(result[configurationKey]);
      return await this.getPriceFromPim(configuration, pimContext, pimTenantId);
    } catch (_error) {
      return;
    }
  }
  
  public async getPriceFromPim(configuration: ModularConfig, context: string, pimTenantId: string, region?: string): Promise<ListPrice> {
    try {
      const response = await this.pimApiClient.calculatePrice(pimTenantId, [context], this.regionService.region, this.currencyService.currency, configuration);      
      const result: IPriceApiResponse = await response.json();
      const price: Price = PriceFactory.createFromBackendApi(result);
      return price.listPrice;
    } catch (_error) {
      return;
    }
  }

  public async publicCalculate(configurationType: PimConfigurationKeyMapping, configurationId: string, pimTenantId: string, pimContext: string): Promise<ListPrice> {
    const pimConfigurationKeyMapping: Map<PimConfigurationKeyMapping, {configurationDescriptionResponsePromise: Promise<Response>, configurationDescriptionKey:"default_configuration" | "product_configuration"}> = new Map([
      [PimConfigurationKeyMapping.Default, {
        configurationDescriptionResponsePromise: this.intiaroPublicApiClient.getProductConfigForPricing(pimTenantId, configurationId),
        configurationDescriptionKey:"default_configuration"
      }],
      [PimConfigurationKeyMapping.Custom, {
        configurationDescriptionResponsePromise: this.intiaroPublicApiClient.getDesignConfigForPricing(configurationId),
        configurationDescriptionKey:"product_configuration"
      }]
    ]);

    return await this.getPriceList(
      pimConfigurationKeyMapping.get(configurationType).configurationDescriptionResponsePromise,
      pimConfigurationKeyMapping.get(configurationType).configurationDescriptionKey,
      pimTenantId,
      pimContext
    );
  }
}
