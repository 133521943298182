import { Component, ElementRef, ViewChild } from "@angular/core";

@Component({
  selector: "app-dropdown-list-btn",
  templateUrl: "./dropdown-list-btn.component.html",
  styleUrls: ["./dropdown-list-btn.component.scss"]
})

export class DropdownListBtnComponent {

  public isVisibleList: boolean = false;
  @ViewChild("dropdownListToggler") toggler: ElementRef;

  public toggleList(): void {
    this.isVisibleList = !this.isVisibleList;
    this.handleListener();
  }

  private handleListener(): void {
    this.isVisibleList ? this.addClickListener() : this.removeClickListener();
  }

  private addClickListener(): void {
    document.addEventListener("click", this.clickListener);
  }

  private removeClickListener(): void {
    document.removeEventListener("click", this.clickListener);
  }

  private clickListener = (event: Event) : void => {
    if(this.isVisibleList && !this.toggler?.nativeElement.contains(event.target)) this.toggleList();
  };
}
