import { Injectable } from "@angular/core";
import {  UserService } from "../authentication/user.service";

declare var IntiaroAnalyticsManager: any;

@Injectable({
  providedIn: "root"
})
export class IntiaroAnalyticsClient {
  constructor(
      private userService: UserService
  ) {}

  public sendEvent(eventName: string, eventContextData: any = {}, sendInstantly:boolean = false): void {
    const user = this.userService.getCurrentUser();
    if (user)  eventContextData.user_uuid = user.uuid; 
    IntiaroAnalyticsManager.instance.sendEvent(eventName, eventContextData, sendInstantly);
  }
}

export class IntiaroAnalyticsConstants {
  public static readonly AnalyticsAttributesUserTypeKey = "user_type";
  public static readonly AnalyticsAttributesKioskValue = "kiosk";
  public static readonly AnalyticsAttrFake3DModelRequestedEventName = "fake3DModelRequested";
  public static readonly AnalyticsAttrFake3DRequestEventName = "fake3DModelRequest";
  public static readonly AnalyticsAttrStart3DmodelGenerationEventName = "3DmodelGenerationStarted";
  public static readonly AnalyticsAttrProductListSearchUsed = "productListSearchUsed";
  public static readonly AnalyticsAttrProductTileClicked = "productTileClicked";
  public static readonly AnalyticsAttrProductListFilterApplied = "productListFilterApplied";
  public static readonly AnalyticsAttrDownload3DModelStarted = "download3DModelStarted";
  public static readonly AnalyticsAttrPreviewProductDesignInitiated = "previewProductDesignInitiated";
  public static readonly AnalyticsAttrProductDesignQRCodeButtonClicked = "productDesignQRCodeButtonClicked";
  public static readonly AnalyticsAttrProductDesignInitiated = "productDesignInitiated";
  public static readonly AnalyticsAttrProductDesignCreatePdfSpecsheetReady = "productDesignCreatePdfSpecsheetReady";
  public static readonly AnalyticsAttrProductDesignCreatePdfSpecsheetFailed = "productDesignCreatePdfSpecsheetFailed";
  public static readonly AnalyticsAttrProductDesignCreatePdfSpecsheetClicked = "productDesignCreatePdfSpecsheetClicked";
  public static readonly AnalyticsAttrProductDesignExportImageClicked = "productDesignExportImageClicked";
  public static readonly AnalyticsAttrProductDesignGenerate3dModelClicked = "productDesignGenerate3dModelClicked";
}
