/* eslint-disable quotes */
import {Component, Input } from "@angular/core";

@Component({
  selector: "app-form-field-errors",
  template: '<span class="form-field-errors-mesage" *ngIf="message"><i class="form-field-errors-information"></i>{{message}}</span>',
  styleUrls: ["./form-field-errors.component.scss"]
})
export class FormFieldErrorsComponent {

  @Input() public message = ""; 

  constructor() {}

}
