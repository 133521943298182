import { CanDeactivateFn } from "@angular/router";
import { Observable, of } from "rxjs";

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
  confirmNavigation?: Observable<boolean>;
}

export const hasUnsavedChangesGuard: CanDeactivateFn<ComponentCanDeactivate> = (component: ComponentCanDeactivate): Observable<boolean> | boolean => {
  if (component.canDeactivate) {
    const confirmation = component.canDeactivate();
    if (confirmation instanceof Observable) {
      return confirmation;
    } else {
      return of(confirmation);
    }
  } else {
    return true;
  }
};
