import { Component, HostListener } from "@angular/core";
import { MyProfileAbstractComponent, requestErrorsMessages } from "../../my-profile-abstract.component";
import { Router } from "@angular/router";
import { Db3DApiBackendClient } from "src/app/services/api/db3d-api-backend-client.service";
import { NavigationService, OutletsRegistry } from "src/app/services/NavigationService.service";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";
import { AvatarService } from "src/app/services/avatar.service";
import { UserDetailsService } from "src/app/services/user-details.service";
import { ToastService, ToastType } from "src/app/services/toast.service";
import { PopupService } from "src/app/services/popup.service";
import { Observable, Subject } from "rxjs";
import { ComponentCanDeactivate } from "src/app/services/guards/pendingChangesGuard";

@Component({
  selector: "app-profile-picture-edit",
  templateUrl: "./profile-picture-edit.component.html",
  styleUrls: ["../profile-picture.component.scss", "../../my-profile-subcomponents.component.scss"]
})

export class ProfilePictureEditComponent extends MyProfileAbstractComponent implements ComponentCanDeactivate {

  public readonly defaultAvatarIcon: string = "assets/images/icons/avatar-icon.svg";
  public customAvatarIconPath: string;
  public isNewAvatarChoosen: boolean = false;
  public errorMsg: string;
  public priorityForUserDecisionOfRedirection: boolean = false;
  public validateAvatarFile: File;
  private profileImagePathBeforeUploadNewFile: string;
  private confirmNavigationSubject: Subject<boolean> = new Subject<boolean>();
  private isRequestProcessing: boolean = false;
  private isCancelProcessing: boolean = false;

  constructor(
    db3DApiBackendClient: Db3DApiBackendClient,
    wlConfigService: WhiteLabelConfigurationService,
    userDetailsService: UserDetailsService,
    private navigationService: NavigationService,
    private router: Router,
    public avatarService: AvatarService,
    private toastService: ToastService,
    private popupService: PopupService
  ) {
    super(db3DApiBackendClient, wlConfigService, userDetailsService);
  }

  @HostListener("window:beforeunload", ["$event"])
  windowBeforeUnload($event: BeforeUnloadEvent) {
    if(this.isNewAvatarChoosen) $event.returnValue = "Your data will be lost";
  }
  canDeactivate(): Observable<boolean> | boolean {
    const popupDataUnsaved = {
      title: "You have unsaved changes",
      text: ["Save changes before leaving"],
      buttons: [
        {
          text: "Save changes",
          callback: () => {
            this.priorityForUserDecisionOfRedirection = true;
            this.saveForm();
            this.confirmNavigationSubject.next(true);
            this.popupService.hidePopup();
          }
        }
      ],
      secondaryButtons: [
        {
          text: "Don't save and leave",
          callback: () => {
            this.confirmNavigationSubject.next(true);
            this.popupService.hidePopup();
          }
        }
      ]
    };
    if(this.canLeavePage()) {
      return true;
    } else {
      this.popupService.showPopup(popupDataUnsaved);
      return this.confirmNavigationSubject.asObservable();
    }
  }

  public cancelProfilePictureForm() {
    this.isCancelProcessing = true;
    this.router.navigate([this.navigationService.myProfile, { outlets: { [OutletsRegistry.profilePicture]: null }}]);
  }

  public uploadAvatarFile(file: File): void {
    this.errorMsg = "";
    this.isNewAvatarChoosen = true;
    this.validateAvatarFile = file;
    this.profileImagePathBeforeUploadNewFile = this.profileImagePath;
    this.profileImagePath = this.avatarService.fileSource;
  }

  public resetUploading(): void {
    this.errorMsg = "";
  }

  public saveForm(): void {
    this.isRequestProcessing = true;
    if(this.isNewAvatarChoosen) {
      this.avatarService.updateAvatar(this.validateAvatarFile, this.userDetails.id).subscribe({
        next: (resp) => {
          this.userDetailsService.updateCurrentUser(resp);
          this.toastService.showToast("Changes saved!", ToastType.success);
          if(!this.priorityForUserDecisionOfRedirection) this.router.navigate([this.navigationService.myProfile]);
        },
        error: (err) => {
          this.toastService.showToast("Changes unsaved!", ToastType.error);
          this.profileImagePath = this.profileImagePathBeforeUploadNewFile;
          this.isNewAvatarChoosen = false;
          this.isRequestProcessing = false;
          this.validateAvatarFile = null;

          this.errorMsg = requestErrorsMessages.general;
          if(err.status === 400) this.errorMsg = requestErrorsMessages.err400;
          if(err.status === 403) this.errorMsg = requestErrorsMessages.err403;
          if(err.status === 404) this.errorMsg = requestErrorsMessages.err404;
          if(err.status >= 500) this.errorMsg = requestErrorsMessages.err500;
        }
      });
    }
  }

  private canLeavePage(): boolean {
    return !this.isNewAvatarChoosen || this.isCancelProcessing || this.isRequestProcessing;
  }
}