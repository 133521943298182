/* eslint-disable curly */
/* eslint-disable arrow-body-style */
import { ChangeDetectorRef, Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter } from "@angular/core";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";

declare var IntiaroPlayer: any;
declare var IntiaroConfigurator: any;
@Component({
  selector: "app-product-design-configurator360",
  templateUrl: "./product-design-configurator360.component.html",
  styleUrls: ["./product-design-configurator360.component.scss"]
})
export class ProductDesignConfigurator360Component implements OnInit, AfterViewInit {

  @Output() configurator360ReadyEvent: EventEmitter<boolean> = new EventEmitter();

  @Input() configurationId: string;
  @Input() brandId: string;
  @Input() tearsheetTemplateId: string;
  @Input() showMenu:boolean = true;
  @Input() showRotateHint:boolean = false;
  @Input() isFlexibleSliderShapePreference?: boolean = false;

  @Input() context?: string;
  @Input() saveAndRenderStrategy: string = "progressive";

  @ViewChild("element360", { read: ElementRef })
  private element360: ElementRef;
  hiddenInactiveChoices: boolean = false;

  public static readonly defaultConfiguratorId = "product-design-configurator";

  get defaultConfiguratorId() {
    return ProductDesignConfigurator360Component.defaultConfiguratorId;
  }

  constructor(
    public changeDetector: ChangeDetectorRef,
    private wlConfigurationService: WhiteLabelConfigurationService
  ) {}

  ngOnInit(): void {
    this.wlConfigurationService.whiteLabelSetupConfiguration.subscribe((wlConfig) => {
      this.hiddenInactiveChoices = !wlConfig.configuratorInactiveChoicesVisible;
    });
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    this.createIntiaroInstance();
  }

  public isConfigurator360Loaded:Subject<boolean> = new Subject();

  private async createIntiaroInstance(): Promise<void> {
    await this.create360InstancetRef();
    if (this.showMenu) {
      IntiaroConfigurator.create(ProductDesignConfigurator360Component.defaultConfiguratorId).then(() => this.intiaro360Ready());
    } else{
      IntiaroPlayer.create(ProductDesignConfigurator360Component.defaultConfiguratorId).then(() => this.intiaro360Ready());
    }
  }

  private intiaro360Ready(): void {
    this.isConfigurator360Loaded.next(true);
    this.configurator360ReadyEvent.emit(true);
  }

  private async create360InstancetRef(): Promise<void> {
    this.changeDetector.detectChanges();

    this.element360.nativeElement.id = this.defaultConfiguratorId;

    this.element360.nativeElement.style = "line-height: normal;"; //temporary solution until new release of 360. (bug with wide loading bar)

    this.element360.nativeElement.setAttribute("data-customer-id", "DB3D");
    this.element360.nativeElement.setAttribute("data-tearsheet-template-id", this.tearsheetTemplateId);
    this.element360.nativeElement.setAttribute("data-domain-template", environment.intiaroConfiguratorDomainTemplateKey);
    this.element360.nativeElement.setAttribute("data-product-id", this.configurationId);
    this.element360.nativeElement.setAttribute("data-mode", "user_configuration");
    this.element360.nativeElement.setAttribute("data-customer-id", this.brandId);
    this.element360.nativeElement.setAttribute("data-context", this.context);
    this.element360.nativeElement.setAttribute("data-save-and-render-strategy", this.saveAndRenderStrategy);
    this.element360.nativeElement.setAttribute("data-silo-shoot", "false");
    this.element360.nativeElement.setAttribute("data-slider-shape-preference", this.isFlexibleSliderShapePreference ? "flexible" : "square");
    this.element360.nativeElement.setAttribute("data-hide-unavailable-choices", this.hiddenInactiveChoices.toString());

    if (this.showMenu) {
      this.element360.nativeElement.setAttribute("data-max-width", "1024");
      this.element360.nativeElement.setAttribute("data-max-height", "1260");
    }else{
      this.element360.nativeElement.classList.add("intiaro360player");
      this.element360.nativeElement.style = "max-height: calc(100% - 30px)";
      this.element360.nativeElement.setAttribute("data-silo-shoot", "false");
      this.element360.nativeElement.setAttribute("data-measurements", "false");
      this.element360.nativeElement.setAttribute("data-max-width", "500");
      this.element360.nativeElement.setAttribute("data-max-height", "500");
    }
  }

}
