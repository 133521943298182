import { Injectable } from "@angular/core";
import {Currency, CurrencyService} from "./currencyService";

@Injectable({
  providedIn: "root"
})
export class RegionService {
  private regionToCurrencyMap: Map<string, string> = new Map([[Currency.USD, "en_US"], [Currency.CAD, "en_CA"]]);

  constructor(private currencyService: CurrencyService) {}
  get region(): string {
    const currency = this.currencyService.currency;
    return  this.regionToCurrencyMap.get(currency);
  }
}
