/* eslint-disable arrow-body-style */
/* eslint-disable curly */
import { Component, OnDestroy, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { saveAs } from "file-saver";
import { FooterService } from "../../services/footer.service";
import { IProductDesignApiResponse, ProductDesignFactory } from "../../factories/product-design-factory";
import { IntiaroAnalyticsClient, IntiaroAnalyticsConstants } from "../../services/analytics/intiaro-analytics.service";
import { HttpClient } from "@angular/common/http";
import { ProductDesign } from "../../data-models/product-design";
import { IPopupContent, PopupService } from "../../services/popup.service";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { ImageExportPopupComponent } from "../image-export-popup/image-export-popup.component";
import { DownloadModelComponent } from "../download-model/download-model.component";
import { ProductDetails } from "../../data-models/types";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";
import { ProductConfigComponent } from "src/app/components/product-config/product-config.component";
import { PimConfigurationKeyMapping, PriceCalculatorService } from "src/app/services/price-calculator.service";
import { ModularConfigFactory } from "src/app/factories/modular-configuration-factory";
import { UserService } from "src/app/services/authentication/user.service";
import { ListPrice } from "src/app/data-models/price.model";
import { NavigationService } from "src/app/services/NavigationService.service";
import { ShareDialogComponent } from "../share-dialog/share-dialog.component";
import { DyanmicPopupService } from "src/app/services/dynamic-popup.service";
import { WhiteLabelSetupConfiguration } from "src/app/data-models/wl-account-setup/wl-config-data.model";
import { IntiaroQrCodeTemplateService } from "src/app/services/integrationQrCode.service";

declare var clearIntiaroInstances: any;
declare var getIntiaroPlayer: any;

type UtmParam = {name: string, value: string};
export enum utmQrCodeSource {
  name = "utm_source",
  value = "qrcode"
}
export enum productDesignViewQUeryParams {
  designId = "design-id"
}
enum qrCodeButtonText {
  initial = "Print QR code",
  generating = "Generating..."
}

@Component({
  selector: "app-product-design",
  templateUrl: "./product-design.component.html",
  styleUrls: ["./product-design.component.scss"],
})
export class ProductDesignComponent implements OnInit, OnDestroy {
  public productDesignId: string;
  public productDesign: ProductDesign;
  protected readonly environment = environment;
  private productDesignFactory: ProductDesignFactory = new ProductDesignFactory();
  private defaultPdfSpecsheetImageAngles = [0, 30, 90];
  public designDetails: ProductDetails | undefined;
  private modularConfigFactory: ModularConfigFactory = new ModularConfigFactory();
  public designPrice: number;
  public designPriceCurrency: string;
  public isDisabledQRCodeButton: boolean = false;
  public printQRcodeButtonText: string = qrCodeButtonText.initial;

  public toolsActive:boolean = false;
  public utmQRcodeSource: UtmParam = {name: utmQrCodeSource.name, value: utmQrCodeSource.value};

  public priceDetails: ListPrice = null;

  public wlConfig: WhiteLabelSetupConfiguration;

  private intiaroQrCodeInstance: HTMLElement;

  constructor(
    private activatedRoute: ActivatedRoute,
    private footerService: FooterService,
    private intiaroAnalyticsClient: IntiaroAnalyticsClient,
    private httpClient: HttpClient,
    private popupService: PopupService,
    private dyanmicPopupService: DyanmicPopupService,
    private dialog: MatDialog,
    private router: Router,
    private whiteLabelConfigurationService: WhiteLabelConfigurationService,
    private priceCalculatorService: PriceCalculatorService,
    public navigationService: NavigationService,
    public userService: UserService,
    private intiaroQrCodeTemplateService: IntiaroQrCodeTemplateService
  ) {}

  ngOnInit(): void {
    this.footerService.hide();
    this.productDesignId = this.activatedRoute.snapshot.paramMap.get("id");
    this.getProductDesignData();
    this.intiaroAnalyticsClient.sendEvent(
      IntiaroAnalyticsConstants.AnalyticsAttrProductDesignInitiated,
      {id: this.productDesignId}
    );

    this.whiteLabelConfigurationService.whiteLabelSetupConfiguration.subscribe((wlConfig: WhiteLabelSetupConfiguration) => {
      this.wlConfig = wlConfig;
    });
  }

  public intiaro360PlayerReady(_value:boolean): void {
    this.toolsActive = true;
  }

  public isQrCodeButtonAvailable(): boolean {
    return this.intiaroQrCodeTemplateService.isEnable && !!this.intiaroQrCodeInstance;
  }

  async getProductDesignData(): Promise<void> {
    const productDesignDetailApiUrl = `${environment.db3dBackendDomain}/api/product-design/${this.productDesignId}/`;
    let productDesignData: any;

    try {
      productDesignData = await this.httpClient.get<IProductDesignApiResponse>(productDesignDetailApiUrl).toPromise();
    } catch(err) {
      if(err.status === 404)
        this.router.navigate([this.navigationService.pageNotFound]);

      return;
    }
    if(productDesignData !== undefined) {
      this.productDesign = this.productDesignFactory.createFromBackendApi(productDesignData);
      this.setProductDetailsData(
        this.productDesign.productName,
        this.productDesign.brand_name,
        this.productDesign.productVersionName,
        this.productDesign.categories
      );

      if (this.isPriceEnable()) {
        this.priceDetails = await this.priceCalculatorService.publicCalculate(PimConfigurationKeyMapping.Custom, this.productDesign.customConfigurationId, this.productDesign.pim_tenant_id, this.productDesign.pim_context);
      }
    }

    this.intiaroQrCodeInstance = this.intiaroQrCodeTemplateService.createTemplateInstance(
      this.productDesign.productName,  
      this.productDesign.brand_name, 
      this.getCustomProductUrl(this.utmQRcodeSource)
    );

  }
  public isPriceEnable(): boolean {
    return this.userService.canUserViewPrices() && !!this.productDesign.pim_context;
  }

  createPdfGenerationInProgressPopup(): void {
    const options = {
      title: "Please wait",
      text: [
        "Your request is being processed. Please wait.",
      ]
    };

    this.popupService.showPopup(options);
  }

  getIntiaroConfiguratorInstance(): any {
    return getIntiaroPlayer("product-design-configurator");
  }

  public getCustomProductUrl(utmParam?: UtmParam): string {
    const currentProtocol = window.location.protocol;
    const currentDomain = window.location.host;
    const pathWithNextQueryParam = ProductConfigComponent.getPathToCustomisedProduct(this.productDesign.productId.toString(), this.productDesign.customConfigurationId);
    const url = new URL(`${currentProtocol}/${currentDomain}/${pathWithNextQueryParam}`);
    if(utmParam) url.searchParams.set(utmParam.name, utmParam.value);
    if(this.productDesign.id) url.searchParams.set(productDesignViewQUeryParams.designId, this.productDesign.id);
    return url.toString();
  }

  public getPreviewProductUrl(productDesignId: string): string {
    const url = new URL(`https://${this.wlConfig.domain}/product-design/${productDesignId}/preview`);
    return url.toString();
  }

  onPdfFileReady(url: string): void {
    saveAs(url, `${this.productDesignId}_summary.pdf`);
    this.intiaroAnalyticsClient.sendEvent(
      IntiaroAnalyticsConstants.AnalyticsAttrProductDesignCreatePdfSpecsheetReady,
      {id: this.productDesignId, name: this.productDesign.name, value: url}
    );
    this.popupService.hidePopup();
  }

  openPdfGenerateFailPopUp(): void {
    const options = {
      title: "Download PDF",
      text: [
        "Your request could not be completed. Please try again later.",
      ],
      buttons: [
        {
          text: "Close",
          callback: () => {
            this.popupService.hidePopup();
          }
        }
      ]
    };

    this.popupService.showPopup(options);
  }

  onPdfFileFail(): void {
    this.intiaroAnalyticsClient.sendEvent(
      IntiaroAnalyticsConstants.AnalyticsAttrProductDesignCreatePdfSpecsheetFailed,
      {id: this.productDesignId, name: this.productDesign.name}
    );
    this.openPdfGenerateFailPopUp();
  }

  generatePdfSpecsheet(): void {
    this.intiaroAnalyticsClient.sendEvent(
      IntiaroAnalyticsConstants.AnalyticsAttrProductDesignCreatePdfSpecsheetClicked,
      {id: this.productDesignId, name: this.productDesign.name}
    );
    this.createPdfGenerationInProgressPopup();
    const intiaroConfigurator = this.getIntiaroConfiguratorInstance();
    intiaroConfigurator.getProductSummaryUrl(
      this.defaultPdfSpecsheetImageAngles,
      (url: string) => {
        this.onPdfFileReady(url);
      },
      () => {
        this.onPdfFileFail();
      }
    );
  }

  createImageExportPopup(): any {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = "db3d-popup-wrapper";
    dialogConfig.data = {
      support360: true
    };

    return this.dialog.open(ImageExportPopupComponent, dialogConfig);
  }

  generateImage(): void {
    const dialogRef = this.createImageExportPopup();

    const intiaroConfigurator = this.getIntiaroConfiguratorInstance();
    const angle = intiaroConfigurator.getAngle();
    const imageUrl = intiaroConfigurator.getFrameUrl(angle);

    this.intiaroAnalyticsClient.sendEvent(
      IntiaroAnalyticsConstants.AnalyticsAttrProductDesignExportImageClicked,
      {id: this.productDesignId, name: this.productDesign.name, value: imageUrl}
    );

    if (dialogRef.componentInstance) {
      dialogRef.componentInstance.product = {
        status: "success",
        image_url: imageUrl
      };
    }
  }

  get get360ConfiguratorId() {
    return "product-design-configurator";
  }

  private generate3dModelPending: boolean = false;
  async generate3dModel(): Promise<void> {
    if (this.generate3dModelPending) return;
    this.generate3dModelPending = true;
    try {
      this.intiaroAnalyticsClient.sendEvent(IntiaroAnalyticsConstants.AnalyticsAttrProductDesignGenerate3dModelClicked, {
        productconfigurationid: this.productDesignId,
        product_name: this.productDesign.name,
        program_name: this.productDesign.productVersionName,
        brand_name: this.productDesign.brand_name,
      });
      const fileName = this.productDesign.productName;
      const dialogConfig = new MatDialogConfig();

      const configuratorId = this.get360ConfiguratorId;
      const configurator360 = getIntiaroPlayer(configuratorId);
      const productConfiguration = await configurator360.getModularConfiguration(
        true,
        (configuration) => configuration,
        (_error) => {
          this.generate3dModelPending = false;
          return null;
        }
      );
      const angle = configurator360.getAngle();
      const productImageUrl: string = configurator360.getFrameUrl(angle);

      dialogConfig.height = "470px";
      dialogConfig.width = "704px";
      dialogConfig.panelClass = "db3d-popup-wrapper";
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        name: fileName,
        product: this.productDesign,
        configuration: productConfiguration,
        support360: true,
        productId: this.productDesign.productId,
        productVersion: this.productDesign.productVersion,
        productImageUrl
      };

      this.dialog.open(DownloadModelComponent, dialogConfig);

      this.generate3dModelPending = false;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
      this.generate3dModelPending = false;
    }
  }

  async getProductConfiguration(): Promise<any> {
    return await getIntiaroPlayer(this.get360ConfiguratorId).getModularConfiguration(
      true,
      (configuration) => configuration,
      (_error) => {
        return null;
        // todo capture sentry and throw an error
      }
    );

  }

  ngOnDestroy(): void {
    this.footerService.show();
    clearIntiaroInstances();
    this.intiaroQrCodeTemplateService.removeTemplateInstance();
  }

  editProductDesign(): void {
    this.router.navigate([this.navigationService.productDesignEdit(this.productDesignId)]);
  }

  setProductDetailsData(productName: string, brandName: string, programName: string, categories: string): void {
    this.designDetails = {
      productName: productName,
      brandName: brandName,
      programName: programName,
      categories: categories
    };
  }

  goBackToProductDesignsList(): void {
    this.router.navigate([this.navigationService.productList], { queryParams: { type: "custom" }});
  }

  createQrcodePrintView() {
    const generatingQRcodeDeleyMs = 500;
    this.printQRcodeButtonText = qrCodeButtonText.generating;
    this.isDisabledQRCodeButton = true;

    /* settimeout is necessary to avoid of multiplication of analytics event generating and change button text */
    setTimeout(() => {
      this.intiaroQrCodeTemplateService.printTemplate();

      this.intiaroAnalyticsClient.sendEvent(
        IntiaroAnalyticsConstants.AnalyticsAttrProductDesignQRCodeButtonClicked,
        {
          product_configuration_id: this.productDesign.productId,
          custom_configuration_id: this.productDesign.id,
          name: this.productDesign.name,
          product_name: this.productDesign.productName
        }
      );

      this.isDisabledQRCodeButton = false;
      this.printQRcodeButtonText = qrCodeButtonText.initial;
    }, generatingQRcodeDeleyMs);
  }

  public showMyDesignShareDialog(): void {

    const options: IPopupContent = {
      ngDynamicComponent: {
        component: ShareDialogComponent,
        inputs:{
          designUuid: this.productDesignId,
          productConfigurationId: this.productDesign.customConfigurationId,
          designPreviewUrl : this.getPreviewProductUrl(this.productDesignId)
        }
      }
    };
    setTimeout(() => {
      this.dyanmicPopupService.showPopup(options);
    }, 500);
  }
}
