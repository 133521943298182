import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

declare var $: any;
declare var _Intiaro_initShowInIntiaro_: any;


@Component({
  selector: 'app-shared-design',
  templateUrl: './shared-design.component.html',
  styleUrls: ['./shared-design.component.css']
})
export class SharedDesignComponent implements OnInit, AfterViewInit{

  public configId: any;

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.paramMap.subscribe(params => {
      this.configId = params.get('id');
    });
   }

  ngOnInit() {
  }

  ngAfterViewInit()
  {
    _Intiaro_initShowInIntiaro_(true);
  }

}
