import { Component, Input } from "@angular/core";
import { NgModel } from "@angular/forms";
import { RegexprPatterns } from "src/app/services/RegexprPatterns";
import { IntiaroAnalyticsClient } from "src/app/services/analytics/intiaro-analytics.service";
import { Db3DApiBackendClient } from "src/app/services/api/db3d-api-backend-client.service";
import { environment } from "src/environments/environment";

export interface IShareDialogPayload {
  email: string,
  design_preview_url: string
}

@Component({
  selector: "app-share-dialog",
  templateUrl: "./share-dialog.component.html",
  styleUrls: ["./share-dialog.component.scss"]
})
export class ShareDialogComponent {

  @Input() private designUuid: string;
  @Input() private designPreviewUrl: string;
  @Input() private productConfigurationId: string;

  public firstStepVisible: boolean = true;

  private shereDesignPendin: boolean = false;

  public email: string;
  public errorMessage: string = "";
  public sendSuccess: boolean = false;

  public get emailPatternRegexpr(): RegExp {
    return RegexprPatterns.emailRegxExpr;
  }

  constructor(
    private db3DApiBackendClient :Db3DApiBackendClient,
    private intiaroAnalyticsClient: IntiaroAnalyticsClient
  ) {}

  public onChangeEmail(_event: Event): void {
    this.sendSuccess = false;
  }

  public shereDesign(): void {
    if (this.shereDesignPendin) return;
    this.shereDesignPendin = true;
    const intiaroAnalyticsClientPayload = {
      id: this.designUuid,
      productConfigurationId: this.productConfigurationId
    };

    this.intiaroAnalyticsClient.sendEvent("productDesignShareButtonClicked", intiaroAnalyticsClientPayload);

    const payload: IShareDialogPayload = {
      email: this.email,
      design_preview_url: this.designPreviewUrl
    };
    this.db3DApiBackendClient.shareDesign(environment.db3dBackendDomain, this.designUuid, payload).subscribe({
      next:(_value) => {
        this.intiaroAnalyticsClient.sendEvent("productDesignShareViaEmailCompleted", {...intiaroAnalyticsClientPayload, value: this.email});
        this.errorMessage = "";
        this.sendSuccess = true;
        this.shereDesignPendin = false;
      },
      error:(error) => {
        if (error.status === 500) this.errorMessage = "Something went wrong, please tray again later.";
        this.shereDesignPendin = false;
      },
    });

  }

  public checkInputValidity(inputModel: NgModel): boolean {
    return inputModel?.invalid && (inputModel?.dirty || inputModel?.touched);
  }

  public isSecondStepAvailable(event: any): void {
    if(event) this.firstStepVisible = false;
  }
}
