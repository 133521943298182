import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GoogleTagManagerHandlerService } from 'src/app/services/google-tag-manager.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IntiaroAnalyticsClient } from '../../services/analytics/intiaro-analytics.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumb.service';
import { saveAs } from 'file-saver';

declare var hj: any;
declare var IntiaroEmbed: any;
declare var IntiaroAnalyticsManager: any;
declare var getIntiaroPlayer: any;

@Component({
  selector: "app-image-export-popup",
  templateUrl: "./image-export-popup.component.html",
  styleUrls: ["./image-export-popup.component.css"],
})
export class ImageExportPopupComponent implements OnInit, OnDestroy {
  public productType: string;
  public product;

  public downloadStatusPending = false;
  private stayFocusedInterval;
  private exportImageDownloadEventName = "exportImageDownloadButtonClicked";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { support360: boolean },
    private dialogRef: MatDialogRef<ImageExportPopupComponent>,
    private googleTagManagerHandlerService: GoogleTagManagerHandlerService,
    private breadcrumbsService: BreadcrumbsService,
    private intiaroAnalyticsClient: IntiaroAnalyticsClient
  ) { }

  ngOnInit() {
    if (!this.data.support360) {
      this.startStayFocusedInterval();
    }
  }

  startStayFocusedInterval() {
    this.stayFocusedInterval = setInterval(() => {
      IntiaroEmbed.focus();
    }, 100);
  }

  closePopup() {
    this.dialogRef.close();
  }

  downloadImage() {
    this.downloadStatusPending = true;
    saveAs(this.product.image_url, `Image`);
    this.downloadStatusPending = false;
    this.closePopup();

    this.sendAnalyticsData();
  }

  sendAnalyticsData() {
    this.googleTagManagerHandlerService.sendEvent(this.exportImageDownloadEventName, this.product);

    this.intiaroAnalyticsClient.sendEvent(this.exportImageDownloadEventName,
      {
        value: this.product.image_url,
        productconfigurationid: this.product.id,
        product_name: this.product.name,
        program_name: this.product.product_version_name,
        brand_name: this.product.brand_name,
        mode: this.product.productType
      });
    hj('tagRecording', ['export_image']);
  }

  ngOnDestroy() {
    clearInterval(this.stayFocusedInterval);
  }
}
