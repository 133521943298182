export class Fake3D {
    size: string;
    format: string;
    custom_configuration_uuid: string;

    constructor( size = '', format='', customConfigUuid = '') {
        this.size = size;
        this.format = format;
        this.custom_configuration_uuid = customConfigUuid;
    }
}
