import { Component, Input, OnInit } from "@angular/core";
import { ElementOption, ProductOptions } from "../../configuration-details/configuration-details.component";

@Component({
  selector: "app-design-configuration",
  templateUrl: "./design-configuration.component.html",
  styleUrls: ["./design-configuration.component.scss"]
})

export class DesignConfigurationComponent implements OnInit {

  @Input() configuration: any;
  public config: Array<ElementOption>;

  ngOnInit(): void {
    this.configuration.subscribe( (config: ProductOptions) => {
      this.config = config.elements;
    });
  }
}
