import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { UserDetails } from "src/app/data-models/manage-users/user-details.model";
import { UsersDetailsFactory } from "src/app/factories/user-details-factory";
import { Db3DApiBackendClient } from "src/app/services/api/db3d-api-backend-client.service";
import { UserDetailsService } from "src/app/services/user-details.service";
import { environment } from "src/environments/environment";
import { RegexprPatterns } from "src/app/services/RegexprPatterns";
import { requestErrorsMessages } from "../../my-profile/my-profile-abstract.component";
import { AvatarService } from "src/app/services/avatar.service";
import { NgForm } from "@angular/forms";
import { BehaviorSubject, combineLatest } from "rxjs";

export interface UserBusinessCardFormData {
  name: {value: string, error: string},
  phoneNumber: {value: string, error: string},
  email: {value: string, error: string},
  profileImage: {value: string, error: string}
}

@Component({
  selector: "app-share-dialog-first-step",
  templateUrl: "./share-dialog-first-step.component.html",
  styleUrls: ["../share-dialog.component.scss"]
})
export class ShareDialogFirstStepComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @Output() secondStepAvailable: EventEmitter<boolean> = new EventEmitter();

  public userDetails: UserDetails;
  public userDetailsFactory: UsersDetailsFactory = new UsersDetailsFactory();
  private initialUserName: string = "";
  private intialPhoneNumber: string = "";
  public firstStepErrorMsg: string = "";
  public userBusinessCardFormData: UserBusinessCardFormData = {
    name: {value: "", error: ""},
    phoneNumber: {value: "", error: ""},
    email: {value: "", error: ""},
    profileImage: {value: "", error: ""}
  };
  public newAvatarFile: File;
  public newAvatarFilePath: string;
  isSavebuttonValid: boolean = false;
  isNewAvatarChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public get phoneNumberPatternRegexpr(): RegExp {
    return RegexprPatterns.phoneNumberRegxExpr;
  }

  @ViewChild("fullName") fullName: NgForm;
  @ViewChild("phoneNumber") phoneNumber: NgForm;
  @ViewChild("shareDesignForm") shareDesignForm: NgForm;

  constructor(
    private db3DApiBackendClient: Db3DApiBackendClient,
    private userDetailsService: UserDetailsService,
    public avatarService: AvatarService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.userBusinessCardFormData.name.value = this.userDetailsService.currentUserDetails.value.name;
    this.userBusinessCardFormData.phoneNumber.value = this.userDetailsService.currentUserDetails.value.phone_number;
    this.userBusinessCardFormData.email.value = this.userDetailsService.currentUserDetails.value.email;
    this.userBusinessCardFormData.profileImage.value = this.userDetailsService.currentUserDetails.value.profile_image;
    if(this.userDetailsService.currentUserDetails.value.name) this.initialUserName = this.userDetailsService.currentUserDetails.value.name;
    this.intialPhoneNumber = this.userDetailsService.currentUserDetails.value.phone_number;
  }

  ngAfterViewInit(): void {
    combineLatest([this.fullName.valueChanges, this.phoneNumber.valueChanges, this.isNewAvatarChanged]).subscribe(([fullName, phoneNumber, isNewAvatarChanged]) => {
      this.isSavebuttonValid = this.fullName?.valid && this.phoneNumber?.valid && !this.isInitialDataTheSameAsNewDataInFirstStepForm(fullName, phoneNumber);
    });
  }

  ngAfterViewChecked(): void {
    if(!this.userBusinessCardFormData.name.value) this.userBusinessCardFormData.name.error = "Name is required.";
    this.changeDetector.detectChanges();
  }

  public goToNextStep(): void {
    this.secondStepAvailable.emit(true);
  }

  public updateUserData(): void {
    this.patchNewUserData();
  }

  public isInitialDataTheSameAsNewDataInFirstStepForm(name, phone): boolean {
    return (name === this.initialUserName && phone === this.intialPhoneNumber && this.newAvatarFile === undefined);
  }

  public restoreInitialUserData(): void {
    this.userBusinessCardFormData.name.value = this.initialUserName;
    this.userBusinessCardFormData.phoneNumber.value = this.intialPhoneNumber;
  }

  public clearFirstStepErrors(): void {
    this.firstStepErrorMsg = "";
    this.userBusinessCardFormData.name.error = "";
    this.userBusinessCardFormData.phoneNumber.error = "";
  }

  private patchNewUserData(): void {
    this.clearFirstStepErrors();

    const formData: FormData = new FormData();
    if(this.userBusinessCardFormData.name.value !== this.initialUserName) formData.append("name", this.userBusinessCardFormData.name.value);
    if(this.userBusinessCardFormData.phoneNumber.value !== this.intialPhoneNumber) formData.append("phone_number", this.userBusinessCardFormData.phoneNumber.value);
    if(this.newAvatarFile) {
      const newName: string = this.newAvatarFile.lastModified + "_" + this.newAvatarFile.name;
      const newFile: File = new File([this.newAvatarFile], newName);
      formData.append("profile_image", newFile, newName);
    }

    this.db3DApiBackendClient.updateUserDetails(environment.db3dBackendDomain, this.userDetailsService.currentUserDetails.value.id, formData).subscribe({
      next: (resp) => {
        this.userDetailsService.updateCurrentUser(resp);
        this.goToNextStep();
      },
      error: (err) => {
        this.restoreInitialUserData();

        this.firstStepErrorMsg = requestErrorsMessages.general;
        if(err.status === 400) this.firstStepErrorMsg = requestErrorsMessages.err400;
        if(err.status === 403) this.firstStepErrorMsg = requestErrorsMessages.err403;
        if(err.status === 404) this.firstStepErrorMsg = requestErrorsMessages.err404;
        if(err.status >= 500) this.firstStepErrorMsg = requestErrorsMessages.err500;
        if(err.error.phone_number) this.userBusinessCardFormData.phoneNumber.error = err.error.phone_number;
        if(err.error.full_name) this.userBusinessCardFormData.name.error = err.error.full_name;
        this.newAvatarFilePath = "";
      },
    });
  }

  public setNewAvatar(file: File): void {
    this.newAvatarFile = file;
    this.newAvatarFilePath = this.avatarService.fileSource;
    this.isNewAvatarChanged.next(true);
  }
}
