import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, EmbeddedViewRef  } from "@angular/core";
import { ToastErrorComponent } from "../components/toast/toast-error/toast-error.component";
import { ToastBaseComponent } from "../components/toast/toast-base.component";
import { ToastSuccessComponent } from "../components/toast/toast-success/toast-success.component";
import { ToastWarningComponent } from "../components/toast/toast-warning/toast-warning.component";
import { ComponentType } from "@angular/cdk/portal";

export enum ToastType {
  success = "success-toast",
  error = "error-toast",
  warning = "warning-toast",
}

@Injectable()
export class ToastService {

  private toastComponentRefs: ComponentRef<ToastBaseComponent>[] = [];
  private readonly timeout = 6000;
  public toastComponentsMap: Map<string, ComponentType<any>> = new Map([
    [ToastType.success, ToastSuccessComponent],
    [ToastType.error, ToastErrorComponent],
    [ToastType.warning, ToastWarningComponent]
  ]);

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {}

  public showToast(message: string, toastType: string): void {
    const toastPattern = this.toastComponentsMap.get(toastType);
    if(toastPattern) {
      const toastComponentRef = this.createToastComponentRef(toastPattern);
      this.configureToastComponent(toastComponentRef as ComponentRef<ToastBaseComponent>, message);
      this.displayToast(toastComponentRef as ComponentRef<ToastBaseComponent>);
      this.scheduleHideToast(toastComponentRef as ComponentRef<ToastBaseComponent>);
    }
  }

  private createToastComponentRef(toastPattern: any): ComponentRef<unknown> {
    const factory = this.componentFactoryResolver.resolveComponentFactory(toastPattern);
    return factory.create(this.injector);
  }

  private configureToastComponent(toastComponentRef: ComponentRef<ToastBaseComponent>, message: string): void {
    const toastInstance = toastComponentRef.instance;
    toastInstance.message = message;
    toastInstance.closeToastFlag.subscribe((ev) => {
      this.hideToast(toastComponentRef);
    });
    this.toastComponentRefs.push(toastComponentRef);
  }

  private displayToast(toastComponentRef: ComponentRef<ToastBaseComponent>): void {
    this.appRef.attachView(toastComponentRef.hostView);
    const domElem = (toastComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    if (this.toastComponentRefs.length > 1) this.moveToast(domElem);
    document.body.appendChild(domElem);
  }

  private scheduleHideToast(toastComponentRef: ComponentRef<ToastBaseComponent>): void {
    setTimeout(() => {
      this.hideToast(toastComponentRef);
    }, this.timeout);
  }

  public hideToast(toastComponentRef: any) {
    const index = this.toastComponentRefs.indexOf(toastComponentRef);
    if (index !== -1) {
      this.appRef.detachView(toastComponentRef.hostView);
      toastComponentRef.destroy();
      this.toastComponentRefs.splice(index, 1);
    }
  }

  private moveToast(el: HTMLElement): void {
    const factor = this.toastComponentRefs.length;
    const toast: HTMLElement = el.querySelector(".toast-message-container");
    toast.style.bottom = factor * 7 + "%";
  }
}
