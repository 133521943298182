import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

export enum SelectType {
  singular = "singular",
  multiple = "multiple"
}

@Component({
  selector: "app-db3d-select",
  templateUrl: "./db3d-select.component.html",
  styleUrls: ["./db3d-select.component.scss"]
})
export class Db3dSelectComponent implements OnInit {

  public localDataModel: any;
  @Input() set interactableFilters(value: boolean) {
    this.disabled = !value;
  }
  @Input() set dataModel(value: any) {
    // deep copy
    this.localDataModel = JSON.parse(JSON.stringify(value));
  }

  @Input() unselectedLabel: string;     // label to be displayed when no options are selected ( default "All");
  @Input() hideSelectLabel = false;     // Should select label be visible?
  @Input() allowEmptySelection = true;  // Is unselecting all options allowed?

  @Output() dataModelChange = new EventEmitter<any>();

  public opened = false;
  public disabled = false;
  private savedState: any;

  private labelsLimit = 3; // maximum number of labels of currently selected options. If more is selected label is turned to "Multiple"
  public selectedOptions;
  public selectedIcon;

  private disableTime = 300;
  public showApplyButton = true;

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.setDefaultFilters();
    this.setUnselectedLabel();
    this.initializeOptions();

    if (this.localDataModel.type === SelectType.singular) this.showApplyButton = false;

  }

  setDefaultFilters() {
    this.activatedRoute.queryParams.subscribe(async(params) => {
      if (params.type !== undefined) {
        this.uncheckAllOptions();
        this.setUnselectedLabel();
        this.opened = false;
      }
    });
  }

  setUnselectedLabel() {
    const label = this.unselectedLabel ? this.unselectedLabel : "All";
    this.selectedOptions = [label];
  }

  initializeOptions() {
    if (!this.allowEmptySelection) {
      let checkedCount = 0;

      this.localDataModel.options.forEach((option) => {
        if (option.checked) checkedCount++;
      });

      if (this.localDataModel.options.length > 0 && checkedCount === 0 ) {
        this.localDataModel.options[0].checked = true;
        this.applyChanges();
      }
    }

    this.updateSelectedLabel();
  }

  filterToggle() {
    this.opened = !this.opened;

    if (this.opened) this.saveCurrentState();
  }

  onOptionChanged(event, option) {

    if (!event.target.checked && !this.allowEmptySelection) {
      event.target.checked = true;
      return;
    }

    if (event.target.checked && this.localDataModel.type === SelectType.singular) this.uncheckAllOptions();

    option.checked = event.target.checked;
    this.updateSelectedLabel();

    if (this.localDataModel.type === SelectType.singular) this.filterToggle();

    this.applyChanges(option);
  }

  uncheckAllOptions() {
    this.localDataModel.options.forEach((option) => {
      option.checked = false;
    });
  }

  updateSelectedLabel() {
    this.selectedOptions = [];

    this.localDataModel.options.forEach((option) => {
      if (option.checked) {
        this.selectedOptions.push(option.label);

        if (option.icon && this.localDataModel.type === SelectType.singular) {
          this.selectedIcon = option.icon;
        } else {
          this.selectedIcon = null;
        }
      }
    });

    if (this.selectedOptions.length === 0) {
      this.setUnselectedLabel();
    } else if (this.selectedOptions.length > this.labelsLimit) {
      this.selectedOptions = ["Multiple"];
    }
  }

  applyChanges(selectedChoice = null) {
    const updateModel = JSON.parse(JSON.stringify(this.localDataModel));
    updateModel.selectedChoice = selectedChoice;
    this.dataModelChange.emit(updateModel);
  }

  saveCurrentState() {
    this.savedState = JSON.parse(JSON.stringify(this.localDataModel));
  }

  restoreSavedState() {
    this.localDataModel = JSON.parse(JSON.stringify(this.savedState));
    this.updateSelectedLabel();
    this.applyChanges();
  }

  close() {
    this.restoreSavedState();
    this.opened = false;
  }
}
