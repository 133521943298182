import { AfterViewChecked, Component, DoCheck, OnInit, ChangeDetectorRef, HostListener, ViewChild } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { filter, map, mergeMap } from "rxjs/operators";
import { UserDetailsService } from "src/app/services/user-details.service";
import { UtilitiesService } from "src/app/services/utilities/utilities.service";
import { NavbarService } from "../../services/navbar.service";
import { IntiaroAnalyticsConstants } from "../../services/analytics/intiaro-analytics.service";
import { RedirectService } from "src/app/services/redirect.service";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";
import { UserService } from "src/app/services/authentication/user.service";
import { AdminService } from "src/app/services/admin.service";
import { WhiteLabelSetupConfiguration } from "src/app/data-models/wl-account-setup/wl-config-data.model";
import { environment } from "../../../environments/environment";
import { UserDetails } from "src/app/data-models/manage-users/user-details.model";
import { WhiteLabelContextService } from "src/app/services/white-labels/white-label-context.service";
import { NavigationService } from "src/app/services/NavigationService.service";

type Navbar = {
  logo: boolean;
  top: boolean;
  menu: boolean;
};

declare var $: any;

@Component({
  selector: "app-top-navbar",
  templateUrl: "./top-navbar.component.html",
  styleUrls: ["./top-navbar.component.scss"]
})
export class TopNavbarComponent implements OnInit, DoCheck, AfterViewChecked {
  @ViewChild("navbarList") navbarList;
  protected readonly environment = environment;
  public loggedIn = false;
  public navbar: Navbar = {
    logo: true,
    top: true,
    menu: true
  };
  public userDetails: UserDetails;
  public paramNext = "";
  public userLogoUrl: string ="";
  public visibleAdminTab: boolean = false;
  private whiteLabelSlug: string;
  public contactPageVisibile: boolean = false;
  public hamburgerMenuExpanded: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userDetailsService: UserDetailsService,
    private utilitiesService: UtilitiesService,
    public navbarService: NavbarService,
    private redirectService: RedirectService,
    private whiteLabelConfigurationService: WhiteLabelConfigurationService,
    private userService: UserService,
    private changeDetector: ChangeDetectorRef,
    private adminService: AdminService,
    private whiteLabelContextService: WhiteLabelContextService,
    public navigationService: NavigationService
  ) {
  }

  @HostListener("window:resize", ["$event"])
  hideMobileMenuAfterWindowResize(event: Event) {
    this.closeMenu();
  }

  ngOnInit() {
    this.subscribeToLoggedIn();
    this.subscribeToUserDetailsService();
    this.readRouteData();
    this.setUpWLConfigDependecies();
  }

  ngAfterViewChecked(): void {
    this.visibleAdminTab = this.userService.isWhiteLabelAdmin();
    this.changeDetector.detectChanges();
  }

  ngDoCheck(): void {
    this.paramNext = this.redirectService.getRedirectQueryParams(window.location.search);
  }

  private subscribeToLoggedIn(): void {
    this.authService.loggedIn.subscribe((value) => this.onLoginStatusChanged(value));
  }

  private onLoginStatusChanged(isLoggedIn): void {
    if (isLoggedIn === null) return;
    this.loggedIn = isLoggedIn;
  }

  private subscribeToUserDetailsService(): void {
    this.userDetailsService.currentUserDetails.subscribe((value) => this.onUserChanged(value));
  }

  private onUserChanged(details): void {
    this.userDetails = details;
  }

  public is3DModelsButtonVisible(): boolean {
    return !this.userDetails?.analyticsAttributes?.find((attribute) => {
      return attribute.key === IntiaroAnalyticsConstants.AnalyticsAttributesUserTypeKey &&
        attribute.value === IntiaroAnalyticsConstants.AnalyticsAttributesKioskValue;
    }) && this.userService.canViewDownload3D();
  }

  public logout(): void {
    this.authService.logout();
    if(this.hamburgerMenuExpanded) this.closeMenu();
  }

  private readRouteData(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;

            return route;
          }
        }
        ),
      )
      .pipe(
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data),
      )
      .subscribe((event) => {
        this.setupNavbar(event.navbar);
      });
  }

  private setupNavbar(navbar: Navbar): void {
    if (navbar) {
      this.navbar = navbar;
    } else {
      this.navbar = {
        logo: true,
        top: true,
        menu: true
      };
    }
  }

  public toggleMenu(): void {
    this.hamburgerMenuExpanded = !this.hamburgerMenuExpanded;
  }

  public closeMenu(): void {
    this.hamburgerMenuExpanded = false;
    this.setMenuHeight();
  }

  public openMenu(): void {
    this.hamburgerMenuExpanded = true;
    this.setMenuHeight();
  }

  private setMenuHeight() {
    if(window.innerWidth <= 1200) {
      this.navbarList.nativeElement.style.height = `calc(${window.innerHeight}px - 90px)`;
    } else {
      this.navbarList.nativeElement.style.height = "100%";
    }
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }

  public goToAdminView(): void {
    if(this.adminService.currentActiveItem.value) this.router.navigate([this.adminService.currentActiveItem?.value.navigateTo]);
  }

  private setUpWLConfigDependecies(): void {
    const faviconEl = document.querySelector("head").querySelector("[rel=\"icon\"]");

    this.whiteLabelSlug = this.whiteLabelContextService.getCurrentWhiteLabelSlug();
    this.whiteLabelConfigurationService.whiteLabelSetupConfiguration.subscribe( (wlConfiguration: WhiteLabelSetupConfiguration) => {
      if(wlConfiguration.logo === "") {
        if(faviconEl) faviconEl.setAttribute("href", "");
      } else if(wlConfiguration.logo === WhiteLabelConfigurationService.NO_LOGO_URL_FROM_API_MSG) {
        if(faviconEl) faviconEl.setAttribute("href", "favicon.ico");
      } else {
        this.userLogoUrl = wlConfiguration.logo;
        if(faviconEl) faviconEl.setAttribute("href", wlConfiguration.logo);
      }
      this.contactPageVisibile = wlConfiguration.contactPageVisibile;
    });
  }

  public get getRegistrationLink(): string {
    return this.navigationService.registration;
  }

  public get getloginLink(): string {
    return this.navigationService.login;
  }

  public setActiveClassForProductListTab(): boolean {
    return this.activeRoute("product-list") && !this.activeRoute("type=custom") && !this.activeRoute("login");
  }

  public setActiveClassForMyDesignsTab(): boolean {
    return this.activeRoute("product-list?type=custom") || this.activeRoute("product-design");
  }
}
