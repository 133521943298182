/* eslint-disable arrow-parens */
/* eslint-disable arrow-body-style */
// navigation.service.ts

import { Injectable } from "@angular/core";

export class RaoutesRegistry {

  static readonly login: string = "login";

  static readonly verification: string = "verification";

  static readonly registration: string = "registration";

  static readonly lastStep: string = "last-step";

  static readonly registrationCode: string = "code";

  static readonly accountStatus: string = "account-status";

  static readonly passwordReset: string = "password-reset";

  static readonly productList: string = "product-list";

  static readonly about: string = "about";

  static readonly blog: string = "blog";

  static readonly privacy: string = "privacy";

  static readonly terms: string = "terms";

  static readonly shared: string = "shared";

  static readonly models3D: string = "3d-models";

  static readonly productDesignDemo: string = "product-design-demo";

  static readonly contact: string = "contact";

  static readonly productDesign: string = "product-design";

  static readonly productDesignEdit: string = "edit";

  static readonly productDesignPreview: string = "preview";

  static readonly admin: string = "admin";

  static readonly adminUsers: string = "users";

  static readonly adminUsersEdit: string = "edit";
  
  static readonly adminAccountSetup: string = "account-setup";

  static readonly myProfile: string= "my-profile";

  static readonly myProfileEdit: string= "edit";

  static readonly basicInfoEdit: string = "basic-info-edit";

  static readonly profilePictureEdit: string = "profile-picture-edit";

  static readonly pageNotFound: string = "page-not-found";

}

export class OutletsRegistry {
  static readonly basicInfo: string = "basicInfo";
  static readonly profilePicture: string = "profilePicture";
}

@Injectable({
  providedIn: "root",
})
export class NavigationService {

  static create(): NavigationService {
    return new NavigationService();
  }

  private concat(...args: RaoutesRegistry[]): string {
    return args.filter(e => e).join("/");
  }

  public get login(): string { return "/" + RaoutesRegistry.login; }

  public get verification(): string { return "/" + this.concat(RaoutesRegistry.login, RaoutesRegistry.verification); }

  public get registration(): string { return "/" + RaoutesRegistry.registration; }

  public get lastStep(): string { return "/" + this.concat(RaoutesRegistry.registration, RaoutesRegistry.lastStep); }

  public get registrationCode(): string { return "/" + this.concat(RaoutesRegistry.registration, RaoutesRegistry.registrationCode); }

  public get accountStatus(): string { return "/" + RaoutesRegistry.accountStatus; }

  public get passwordReset(): string { return "/" + RaoutesRegistry.passwordReset; }

  public get productList(): string { return "/" + this.concat(RaoutesRegistry.productList); }

  public productListWithId(id?: string): string { return "/" + this.concat(RaoutesRegistry.productList, id); }

  public get about(): string { return "/" + RaoutesRegistry.about; }

  public get blog(): string { return "/" + RaoutesRegistry.blog; }

  public get privacy(): string { return "/" + RaoutesRegistry.privacy; }

  public get terms(): string { return "/" + RaoutesRegistry.terms; }

  public get shared(): string { return "/" + RaoutesRegistry.shared; }

  public get models3D(): string { return "/" + RaoutesRegistry.models3D; }

  public get contact(): string { return "/" + RaoutesRegistry.contact; }

  public productDesign(id: string): string { return "/" + this.concat(RaoutesRegistry.productDesign, id); }

  public productDesignEdit(id: string): string { return "/" + this.concat(RaoutesRegistry.productDesign, id, RaoutesRegistry.productDesignEdit); }

  public productDesignPreview(id: string): string { return "/" + this.concat(RaoutesRegistry.productDesign, id, RaoutesRegistry.productDesignPreview); }

  public get admin(): string { return "/" + RaoutesRegistry.admin; }

  public get adminUsers(): string { return "/" + this.concat(RaoutesRegistry.admin, RaoutesRegistry.adminUsers); }

  public adminUsersWithId(id: number): string { return "/" + this.concat(RaoutesRegistry.admin, RaoutesRegistry.adminUsers, id); }

  public adminUsersWithIdEdit(id: string): string { return "/" + this.concat(RaoutesRegistry.admin, RaoutesRegistry.adminUsers, id, RaoutesRegistry.adminUsersEdit); }
    
  public get adminAccountSetup(): string { return "/" + this.concat(RaoutesRegistry.admin, RaoutesRegistry.adminAccountSetup); }

  public get myProfile(): string { return "/" + RaoutesRegistry.myProfile; }

  public get myProfileEdit(): string { return "/" + this.concat(RaoutesRegistry.myProfile, RaoutesRegistry.myProfileEdit); }

  public get pageNotFound(): string { return "/" + RaoutesRegistry.pageNotFound; }
}
