import { Component, ChangeDetectorRef, AfterViewChecked } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AdminService } from "../../services/admin.service";
import { NavigationService } from "src/app/services/NavigationService.service";

enum menuItemsSlug {
  menageUsers = "users-management",
  accountSetup = "wl-configuration"
}

export class AdminMenuItem {

  constructor(
    public readonly title: string,
    public readonly iconPath: string,
    public readonly navigateTo: string,
    public readonly slug: string, // must be unique
    private active?: boolean
  ) {
    if (this.active === undefined) this.active = false;
  }

  public setNotActive() {
    this.active = false;
  }

  public setActive() {
    this.active = true;
  }

  public isActive() {
    return this.active;
  }
}

export class AdminMenuStructure {
  private readonly navigationService: NavigationService = new NavigationService();
  private adminMenuItems: Array<AdminMenuItem> = [];

  constructor() {
    this.adminMenuItems.push(new AdminMenuItem(
      "Manage users",
      "assets/images/manage_users.svg",
      this.navigationService.adminUsers,
      menuItemsSlug.menageUsers
    ));
    this.adminMenuItems.push(new AdminMenuItem(
      "Settings",
      "assets/images/account_setup.svg",
      this.navigationService.adminAccountSetup,
      menuItemsSlug.accountSetup
    ));
  }

  public getMenuItems() {
    return this.adminMenuItems;
  }
}

@Component({
  selector: "app-admin-view",
  templateUrl: "./admin-view.component.html",
  styleUrls: ["./admin-view.component.scss"]
})
export class AdminViewComponent implements AfterViewChecked {
  public menuStructure: AdminMenuStructure;
  static readonly manageUsersTabMenu = menuItemsSlug.menageUsers;
  static readonly accountSetupTabMenu = menuItemsSlug.accountSetup;
  public isTopNavbarInfoVisible = false;

  constructor(
    private adminService: AdminService,
    private router: Router,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef
  ) {
    this.menuStructure = this.adminService.menuStructure;
    this.route.children[0].data.subscribe((extraData) => {
      this.adminService.setActiveTab(extraData.menuTab);
    });
  }
  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  public changeActiveTab(item: AdminMenuItem): void {
    this.adminService.setActiveTab(item.slug);
    
    this.router.navigate([item.navigateTo], {queryParams: this.route.snapshot.queryParams});
  }

}
