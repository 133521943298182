import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';

@Component({
  selector: 'app-dual-state-button',
  templateUrl: './dual-state-button.component.html',
  styleUrls: ['./dual-state-button.component.scss'],
  animations: [
    trigger('openClose', [
      state('closed', style({
        opacity: 0,
      })),
      state('open', style({
        opacity: 1,
      })),
      transition('closed => open', [
        animate('0.4s')
      ]),
      transition('open => closed', [
        animate('0.3s')
      ]),
    ]),
  ],
})
export class DualStateButtonComponent {

  public contentVisibility: boolean = false;
  private readonly MOBILE_BREAKPOINT = 900;
  @Input() title: string = '';
  @ViewChild('dualStateBtn') dualStateBtn: ElementRef;
  @ViewChild('toggledComponent') toggledComponent: ElementRef;

  public rotateChevron(): void {
    if(this.dualStateBtn.nativeElement.classList.contains('chevron-rotation')) {
      this.dualStateBtn.nativeElement.classList.remove('chevron-rotation');
      this.contentVisibility = false;
    } else {
      this.dualStateBtn.nativeElement.classList.add('chevron-rotation');
      this.contentVisibility = true;
    }
  }

  private isMobileView(screenWidth: number) {
    return (screenWidth > this.MOBILE_BREAKPOINT);
  }

  public isContentVisible(): boolean {
    return (this.isMobileView(window.innerWidth) ? true : this.contentVisibility);
  }
}
