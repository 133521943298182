import { Component } from '@angular/core';
import {GlobalSettingsService} from '../../services/settings/global-settings.service';
import {GoogleTagManagerHandlerService} from "../../services/google-tag-manager.service";
import {GoogleTagManagerService} from "angular-google-tag-manager";
import { IntiaroAnalyticsClient } from '../../services/analytics/intiaro-analytics.service';

declare var IntiaroAnalyticsManager: any;

@Component({
  selector: 'app-request-furniture-ad',
  templateUrl: './request-furniture-ad.component.html',
  styleUrls: ['./request-furniture-ad.component.css']
})
export class RequestFurnitureAdComponent {

  constructor(
    private globalSettingsService: GlobalSettingsService,
    private googleTagManagerHandlerService: GoogleTagManagerHandlerService,
    private intiaroAnalyticsClient: IntiaroAnalyticsClient
  ) { }

  onContactWithUsClicked(){
   this.intiaroAnalyticsClient.sendEvent('productRequestAdClicked');
    this.googleTagManagerHandlerService.sendEvent('productRequestAdClicked');
    window.open(this.globalSettingsService.requestFurnitureAdUrl);
  }
}
