type QualityOf3DModelsKeys = keyof typeof QualityOf3DModels.qualityMap;

export class QualityOf3DModels {
  static qualityMap = { "0.3": "LOW", "0.6": "MED", "1.0": "HIGH" };

  constructor() {}

  static getQualityNameByReductionFactor(quality: QualityOf3DModelsKeys) {
    return QualityOf3DModels.qualityMap[quality];
  }
}
