import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ProductListComponent } from "./components/product-list/product-list.component";
import { AccountSetupWrapperComponent } from "./components/account-setup-wrapper/account-setup-wrapper.component";
import { LoginComponent } from "./components/authentication-module/login/login.component";
import { AccountStatusComponent } from "./components/account-status/account-status.component";
import { SharedDesignComponent } from "./components/shared-design/shared-design.component";
import { AboutComponent } from "./components/about/about.component";
import { BlogComponent } from "./components/blog/blog.component";
import { PrivacyComponent } from "./components/privacy/privacy.component";
import { TermsComponent } from "./components/terms/terms.component";
import { RegistrationCodeComponent } from "./components/registration-code/registration-code.component";
import { RegistrationComponent } from "./components/authentication-module/registration/registration.component";
import { MyModelsComponent } from "./components/my-models/my-models.component";
import { ProductDesignDemoComponent } from "./components/product-design-demo/product-design-demo.component";
import { ContactComponent } from "./components/contact/contact.component";
import { ProductDesignComponent } from "./components/product-design/product-design.component";
import { ProductDesignEditComponent } from "./components/product-design-edit/product-design-edit.component";
import { ProductConfigComponent } from "./components/product-config/product-config.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { AdminViewComponent } from "./components/admin-view/admin-view.component";
import { UserDetailsComponent } from "./components/user-details/user-details.component";
import { UserDetailsEditComponent } from "./components/user-details-edit/user-details-edit.component";
import { UsersListComponent } from "./components/users-list/users-list.component";

import { RegistrationLastStepComponent } from "./components/authentication-module/registration-last-step/registration-last-step.component";
import { VerificationComponent } from "./components/authentication-module/verification/verification.component";
import { ResetPasswordComponent } from "./components/authentication-module/reset-password/reset-password.component";
import { RaoutesRegistry, OutletsRegistry } from "./services/NavigationService.service";

import {UserPermissions} from "./services/authentication/user.service";
import {VerifiedUserGuard} from "./services/guards/verifiedUserGuard";
import {NotLoggedInGuard} from "./services/guards/notLoggedInGuard";
import {UnverifiedUserGuard} from "./services/guards/unverifiedUserGuard";
import {GenericPermissionGuard} from "./services/guards/genericPermissionGuard";
import {hasUnsavedChangesGuard} from "./services/guards/pendingChangesGuard";
import {MyProfileComponent} from "./components/my-profile/my-profile.component";
import { BasicInfoEditComponent } from "./components/my-profile/basic-info/basic-info-edit/basic-info-edit.component";
import { BasicInfoDisplayComponent } from "./components/my-profile/basic-info/basic-info-display/basic-info-display.component";
import { MyDesignPreviewComponent } from "./components/my-design-preview/my-design-preview.component";
import { ProfilePictureDisplayComponent } from "./components/my-profile/profile-picture/profile-picture-display/profile-picture-display.component";
import { ProfilePictureEditComponent } from "./components/my-profile/profile-picture/profile-picture-edit/profile-picture-edit.component";

const routes: Routes = [

  {path: "", pathMatch: "full", component: ProductListComponent, canActivate: [VerifiedUserGuard]},
  {
    path: RaoutesRegistry.login,
    children: [
      {path: "", component: LoginComponent, data: {title: "Login"}, canActivate: [NotLoggedInGuard]},
      {
        path: RaoutesRegistry.verification,
        component: VerificationComponent,
        data: {title: "Account Login Verification"},
        canActivate: [UnverifiedUserGuard]
      },
    ]
  },
  {
    path: RaoutesRegistry.registration,
    children: [
      {path: "", component: RegistrationComponent, data: {title: "Account Creation"}, pathMatch: "full", canActivate: [NotLoggedInGuard]},
      {path: RaoutesRegistry.lastStep, component: RegistrationLastStepComponent, data: {title: "One more thing"}, pathMatch: "full", canActivate: [UnverifiedUserGuard]},
      {
        path: RaoutesRegistry.registrationCode,
        children: [
          {path: RaoutesRegistry.registrationCode, component: RegistrationCodeComponent, data: {title: "Account Creation Account Code"}}
        ]
      },
    ]
  },
  {path: RaoutesRegistry.accountStatus, component: AccountStatusComponent, data: {title: "Account Creation Status"}},
  {path: RaoutesRegistry.passwordReset, component: ResetPasswordComponent, data: {title: "Password Reset"}, canActivate: [NotLoggedInGuard]},

  {path: RaoutesRegistry.productList, children:[
    {path: "", component: ProductListComponent, data: {title: "Product List "}, canActivate: [VerifiedUserGuard]},
    {path: ":id", component: ProductConfigComponent, data: {title: "Product config"}},
  ]},


  {path: RaoutesRegistry.about, component: AboutComponent, data: {title: "About"}},
  {path: RaoutesRegistry.blog, component: BlogComponent, data: {title: "Blog"}},
  {path: RaoutesRegistry.privacy, component: PrivacyComponent, data: {title: "Privacy Policy"}},
  {path: RaoutesRegistry.terms, component: TermsComponent, data: {title: "Terms and Conditions"}},
  {
    path: RaoutesRegistry.shared,
    children: [
      {
        path: ":id", component: SharedDesignComponent,
        data: {
          navbar: {
            title: "Shared Design",
            logo: true,
            top: false,
            menu: false
          }
        }
      },
    ]
  },
  {path: RaoutesRegistry.models3D, component: MyModelsComponent, data: {title: "3D models", requiredPermissions: [UserPermissions.ViewDownload3D]}, canActivate: [GenericPermissionGuard]},
  {path: RaoutesRegistry.productDesignDemo, children: [
    {path: ":id", component: ProductDesignDemoComponent, data: {title: "Product Demo"}},
  ]},
  /* Hidden path until updated video in 'Getting started'
    {path: 'getting-started', component: GettingStartedComponent, data: {title: 'Getting started'}},*/

  {path: RaoutesRegistry.contact, component: ContactComponent, data: {title: "Contact"}},

  {path: RaoutesRegistry.productDesign, children: [
    {path: ":id", children: [
      {path: "", component: ProductDesignComponent, data: {title: "Product design"}, canActivate: [VerifiedUserGuard]},
      {path: RaoutesRegistry.productDesignEdit, component: ProductDesignEditComponent, data: {title: "Design editing"}, canActivate: [VerifiedUserGuard]},
      {path: RaoutesRegistry.productDesignPreview, component: MyDesignPreviewComponent, data: {title: "Design preview"}},
    ]},
  ]},
  {path: RaoutesRegistry.myProfile, component: MyProfileComponent, data: {title: "My profile"}, canActivate: [VerifiedUserGuard],
    children: [
      {path: "", children: [
        {path:"", component: BasicInfoDisplayComponent},
        {path: RaoutesRegistry.basicInfoEdit, component: BasicInfoEditComponent, data: {title: "Edit 'Basic Info'"}, canDeactivate: [hasUnsavedChangesGuard]}
      ], outlet: OutletsRegistry.basicInfo},
      {path: "", children: [
        {path:"", component: ProfilePictureDisplayComponent},
        {path: RaoutesRegistry.profilePictureEdit, component: ProfilePictureEditComponent, data: {title: "Edit 'Profile Picture'"}, canDeactivate: [hasUnsavedChangesGuard]}
      ], outlet: OutletsRegistry.profilePicture}
    ],
  },
  {path: RaoutesRegistry.admin, component: AdminViewComponent, data: {title: "Admin", requiredPermissions: [UserPermissions.Admin]}, canActivate: [GenericPermissionGuard],
    children: [
      {path: "", component: UsersListComponent, data: {menuTab: AdminViewComponent.manageUsersTabMenu }},
      {path: RaoutesRegistry.adminUsers, children: [
        {path: "", component: UsersListComponent, data: {title: "Users list", menuTab: AdminViewComponent.manageUsersTabMenu }},
        {path: ":id", children: [
          {path: "", component: UserDetailsComponent, data: {title: "User details", menuTab: AdminViewComponent.manageUsersTabMenu }},
          {path: RaoutesRegistry.adminUsersEdit, component: UserDetailsEditComponent, data: {title: "User details edit", menuTab: AdminViewComponent.manageUsersTabMenu }, canDeactivate: [hasUnsavedChangesGuard]},
        ]},
      ]},
      {path: RaoutesRegistry.adminAccountSetup, component: AccountSetupWrapperComponent, data: {title: "Account setup", menuTab: AdminViewComponent.accountSetupTabMenu }},
      {path: "**", redirectTo: ""},
    ]
  },

  {path: RaoutesRegistry.pageNotFound, component: PageNotFoundComponent, data: {title: "Page not found"}},
  {path: "**", redirectTo: RaoutesRegistry.pageNotFound}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
