/* eslint-disable quotes */
import {Component, Input } from "@angular/core";

@Component({
  selector: "app-non-field-form-errors",
  template: '<span *ngIf="this.message"><i></i>{{message}}</span>',
  styleUrls: ["./non-field-form-errors.component.scss"]
})
export class NonFieldFormErrorsComponent {

  @Input() public message = ""; 

  constructor() {}

}

