/* eslint-disable arrow-body-style */
import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList } from "@angular/core";
import { Subject } from "rxjs";
import { SimpleSelectOptionComponent } from "./simple-select-option/simple-select-option.component";

@Component({
  selector: "app-simple-select",
  templateUrl: "./simple-select.component.html",
  styleUrls: ["./simple-select.component.scss"]
})
export class SimpleSelectComponent implements AfterContentInit {

  @ContentChildren(SimpleSelectOptionComponent) options: QueryList<SimpleSelectOptionComponent>;

  @Input() defaultValue: string;
  @Output() changeValueEvent: EventEmitter<string> = new EventEmitter();

  public isOpen: boolean = false;

  public checkedOption: SimpleSelectOptionComponent;
  public checkedOptionPreview: string = null;

  private onChangeSubject: Subject<string> = new Subject();

  constructor() {}

  private findOption(value: string) {
    return this.options.find((option) => option.value === value);
  }

  private checkOne(value: string) : void {
    const optionToUncheck: SimpleSelectOptionComponent[] = this.options.filter((option) => option.value !== value);
    optionToUncheck.forEach((option) => {
      option.checked = false;
    });
    const checkedOption = this.findOption(value);
    checkedOption.checked = true;
    this.checkedOptionPreview = checkedOption.abbreviation;
    this.isOpen = false;
  }

  public toggle() {
    this.isOpen = !this.isOpen;
  }

  ngAfterContentInit() {
    this.checkedOption = this.findOption(this.defaultValue);

    Promise.resolve().then(() => {
      this.checkedOption.checked = true;
      this.options.forEach((option) => {
        option.onChangeSubject = this.onChangeSubject;
      });
    });

    this.checkedOptionPreview = this.checkedOption.abbreviation;
      
    this.onChangeSubject.subscribe((value) => {
      this.changeValueEvent.emit(value);
      this.checkOne(value);
    });

  }

  
}
