import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-count-down',
  templateUrl: './count-down.component.html',
  styleUrls: ['./count-down.component.css']
})
export class CountDownComponent implements OnInit, OnDestroy {

  @Input() set dueDate(value: Date){
    this.dDay = value;
  }
  private subscription: Subscription;

  public dateNow = new Date();
  public dDay: Date;
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute  = 60;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;


  private getTimeDifference() {
    if (this.dDay) {
      this.timeDifference = this.dDay.getTime() - new Date().getTime();
      this.allocateTimeUnits(this.timeDifference);
    }
  }

  private allocateTimeUnits(timeDifference) {
    if (timeDifference < 0){
      this.secondsToDday = 0;
      this.minutesToDday = 0;
      this.hoursToDday = 0;
      this.daysToDday = 0;
    }
    else {
      this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
      this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
      this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute));
      //this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
    }
    this.secondsToDday = this.beautifyTimer(this.secondsToDday);
    this.minutesToDday = this.beautifyTimer(this.minutesToDday);
    this.hoursToDday = this.beautifyTimer(this.hoursToDday);
    this.daysToDday = this.beautifyTimer(this.daysToDday);
  }

  private beautifyTimer(timer){
    if (timer < 10){
      if (timer === 0){
        timer = '00';
      }
      else {
        timer = '0' + timer;

      }
    }
    return timer;
  }

  ngOnInit() {
    this.subscription = interval(100)
      .subscribe(x => { this.getTimeDifference(); });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
