import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../environments/environment";
import { AuthService } from "../services/auth.service";

@Injectable({ providedIn: "root" })
export class MyModelsService {
  private itemsLimit: number = 10;
  private page: number = 0;

  public pagesTotal: any = new BehaviorSubject<number>(0);
  public modelsItems: any = new BehaviorSubject<Array<any>>([]);

  public loading: any = new BehaviorSubject<boolean>(true);

  public hasPendingJobs: boolean;
  private updateInverval: any;
  private updateFrequecy: number = 5000;
  public inProgressItems: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public hasNotificationsDisplayed: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private http: HttpClient, private authService: AuthService) {}

  initialize(): void {
    this.subscribeToLoggedIn();
  }

  subscribeToLoggedIn() :void {
    this.authService.loggedIn.subscribe((isLoggedIn:boolean) => this.onLoginStatusChanged(isLoggedIn));
  }

  onLoginStatusChanged(isLoggedIn:boolean): void {
    if (isLoggedIn) {
      this.updateMyModels();
      this.resetUpdateInterval();
    }else{
      if (this.updateInverval) {
        this.stopUpdateInterval();          
      }
    }
  }

  updateMyModels(): any {
    const url = `${environment.db3dBackendDomain}/api/notifications/?items_per_page=${this.itemsLimit}&page=${this.page}`;
    this.http.get<any>(url).subscribe(
      (response) => {
        if (
          this.compareObjects(this.modelsItems.value, response.items) ||
          this.modelsItems.value.length === 0
        ) {
          this.modelsItems.next(response.items);
        }

        if (this.pagesTotal.value !== response.pagination.pages_total) {
          this.pagesTotal.next(response.pagination.pages_total);
        }

        this.hasPendingJobs = Boolean(
          response.items.find((items: any) => items.status != "done")
        );

        if (!this.hasPendingJobs) {
          this.stopUpdateInterval();
        }

        if (this.loading.value) {
          this.loading.next(false);
        }

        this.inProgressItems.next(
          response.items.filter((items: any) => items.status === "in_progress")
            .length
        );

        this.hasNotificationsDisplayed.next(
          response.items.filter(
            (items: any) => !items.displayed || items.status === "in_progress"
          ).length
        );
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  compareObjects(object1: any, object2: any) {
    return JSON.stringify(object1) != JSON.stringify(object2);
  }

  stopUpdateInterval(): void {
    clearInterval(this.updateInverval);
  }

  resetUpdateInterval(): void {
    if (this.updateInverval) {
      clearInterval(this.updateInverval);
    }
    this.updateInverval = setInterval(() => {
      this.updateMyModels();
    }, this.updateFrequecy);
  }

  disableNewLabelForViewedMyModelsItems(): void {
    const displayedItems: Array<any> = [];
    this.modelsItems.value.map((notification: any) => {
      if (!notification.displayed) {
        displayedItems.push({
          id: notification.id,
          displayed: true,
        });
      }
    });

    this.updateMyModelsDisplayedFlag(displayedItems);
  }

  updateMyModelsDisplayedFlag(itemsToUpdate: Array<any>): void {
    this.resetUpdateInterval();
    const displayStatusUrl = `${environment.db3dBackendDomain}/api/notifications/set-displayed/`;
    const httpBody = itemsToUpdate;

    this.http.patch(displayStatusUrl, httpBody).subscribe(
      (response) => {
        this.updateMyModels();
      },
      (error) => {
        throw new Error("3D Models displayed flag change failed");
      }
    );
  }
}
