import { WhiteLabelSetupConfiguration } from "../data-models/wl-account-setup/wl-config-data.model";

export interface IWhiteLabelSetupConfigurationApiResponse {
  name: string;
  slug: string;
  logo: string;
  show_prices: boolean;
  pdf_template: string;
  two_step_registration: boolean;
  login_background: string;
  contact_page_visibile: boolean;
  domain: string;
  qr_code_template_url: string;
  configurator_inactive_choices_visible?: boolean;
}

export class WhiteLabelSetupConfigurationFactory {

  public createFromBackendApi(apiResponse: IWhiteLabelSetupConfigurationApiResponse): WhiteLabelSetupConfiguration {
    return new WhiteLabelSetupConfiguration(
      apiResponse.name,
      apiResponse.slug,
      apiResponse.logo,
      apiResponse.show_prices,
      apiResponse.pdf_template,
      apiResponse.two_step_registration,
      apiResponse.login_background,
      apiResponse.contact_page_visibile,
      apiResponse.domain,
      apiResponse.qr_code_template_url,
      apiResponse.configurator_inactive_choices_visible
    );
  }
}
