import { Injectable } from "@angular/core";
import { BodyForPricing } from "src/app/services/price-calculator.service";
import {Currency} from "../currencyService";
import {ModularConfig} from "../../factories/modular-configuration-factory";
import { IPriceApiResponse } from "src/app/factories/price-factory";
import { environment } from "src/environments/environment";
export interface IPimApiClient {
  calculatePrice(pimTenantId: string, contexts: [string], region: string, currency: Currency, configuration: ModularConfig): Promise<Response>
}
@Injectable({
  providedIn: "root",
})
export class PimApiClient implements IPimApiClient {

  private readonly headers: HeadersInit = new Headers({"Content-Type": "application/json", "Accept": "application/json"});

  public async calculatePrice(pimTenantId: string, contexts: [string], region: string, currency: Currency, configuration: ModularConfig): Promise<Response> {

    const url: string = `${environment.publicApiIntiaro}/price/${pimTenantId}/`;

    const body: BodyForPricing = {
      contexts: contexts,
      region: region,
      currency: currency,
      configuration: configuration
    };

    return fetch(url, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(body),
    });

  }
}

export class PimApiClientMock implements IPimApiClient {
  public async calculatePrice(_pimTenantId: string, _contexts: [string], _region: string, _currency: Currency, _configuration: ModularConfig): Promise<Response> {
    const response: IPriceApiResponse = {
      "list_price": {
        "currency": "USD",
        "value": 1000
      },
      "elements_price": [
        {
          "element_sku": "0000",
          "value": 1000,
          "currency": "USD"
        }
      ]
    };
    return new Response(JSON.stringify(response));
  }
}