export const environment = {
  production: true,
  environment_id: "prod",
  googleTagManagerId: "GTM-MPTRKJM",
  hubspotAccountId: 9267294,
  analytics_url: "https://analytics-backend.intiaro.com/analytics/",
  logout_url: "https://backend.intiaro.com",
  intiaro_embed_url: "https://db3d-backend.intiaro.com",
  main_domain: "https://backend.intiaro.com",
  unity_embed_domain: "https://db3d-backend.intiaro.com",
  login_domain: "https://backend.intiaro.com",
  registration_domain: "https://backend.intiaro.com",
  password_reset_domain: "https://backend.intiaro.com",
  country_list_domain: "https://backend.intiaro.com",
  state_list_domain: "https://backend.intiaro.com",
  product_list_domain: "https://backend.intiaro.com",
  product_details_domain: "https://backend.intiaro.com",
  product_list_filters_domain: "https://backend.intiaro.com",
  save_design_domain: "https://backend.intiaro.com",
  unity_build_domain: "https://db3d-backend.intiaro.com",
  intiaro_api_domain: "https://backend.intiaro.com",
  model_service_api_domain: "https://model-service.intiaro.com/",
  request_furniture_ad_url: "https://demo.db3d.com/3d-model-request",
  player360CustomerId: "DB3D",
  db3dBackendDomain: "https://db3d.backend.intiaro.com",
  productDesignDemoPageToken: "85078205fb4bd6307c519b727c4c07aaf99ad42a",
  intiaroConfiguratorDomainTemplateKey: "prod",
  db3dFrontendDomain: "https://app.db3d.com",
  publicApiIntiaro: "https://public-api.intiaro.com"
};
