import { Component, Input } from "@angular/core";
import { ToastBaseComponent } from "../toast-base.component";

@Component({
  selector: "app-toast-error",
  templateUrl: "../toast-base.component.html",
  styleUrls: ["../toast-base.component.scss"],
})

export class ToastErrorComponent extends ToastBaseComponent {
  icon: string = "assets/images/icons/negative-request.svg";
  backgroundColor: string = "#C53434";
  @Input() message: string = "";
}
