/* eslint-disable arrow-body-style */
/* eslint-disable curly */
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UsersRole } from "../../data-models/manage-users/user-role.model";
import { VisibleBrandsPayload } from "src/app/components/account-setup-wrapper/brands-visibility-form/brands-visibility-form.component";
import { ProductDesignHttpBody } from "../product-savers/product-design-saver.service";
import { IProductDesignApiResponse } from "src/app/factories/product-design-factory";
import { VerificationFormData } from "src/app/components/authentication-module/registration-last-step/registration-last-step.component";
import { AuthPatchUserDetailsData, AuthRegistrationData, AuthResponseData } from "src/app/data-models/auth-response-data.model";
import { UserResponseData } from "../auth.service";
import { IWhiteLabelSetupConfigurationApiResponse } from "src/app/factories/wl-config-data-factory";
import { ShowPrices } from "../white-labels/white-label-configuration.service";
import { ICurrentUserDetails } from "../user-details.service";
import { UpdateUserPayload } from "src/app/data-models/manage-users/user-details-edit-mode.model";
import { IShareDialogPayload } from "src/app/components/share-dialog/share-dialog.component";
import { IProductConfigApiResponse } from "src/app/factories/product-config-factory";

export type QueryParamsForGetUsers = {
  page?: string;
  search?: string;
  ordering?: string;
}

export interface IDb3DApiBackendClient {

  getOtherDesignsResponse(domain:string, id:string): Observable<any>

  getUsersList(domain: string, queryParamsfromUrl?: QueryParamsForGetUsers): Observable<any>
  
  getUserDetailsData(domain: string, id: string): Observable<any>
  
  getUserDetailsById(domain: string, id: string): Observable<any>
  
  getUsersRolesList(domain:string, whiteLabelSlug:string): Observable<any>

  patchUsersRole(domain:string, id: string, payload: {"role": UsersRole}): Observable<any>

  getBrandsVisibility(domain: string, whiteLabelSlug:string): Observable<any>

  patchBrandsVisibility(domain: string, whiteLabelSlug: string, payload: VisibleBrandsPayload): Observable<any>

  getBrandsAvailability(domain: string, whiteLabelSlug:string): Observable<any>

  getProductDesign(domain: string, productDesignId: string): Observable<IProductDesignApiResponse>

  getProductsList(domain: string, qParams: string): Observable<any>

  getMyDesigns(domain: string, qParams: string): Observable<any>

  patchUserDetails(domain: string, userId: number, payload: VerificationFormData): Observable<any>

  getUserDetails(domain: string): Observable<any>

  getUserPermissions(domain: string): Observable<any>

  updateUserDetails(domain: string, userId: number, body: FormData): Observable<any>

  getProductConfig(domain: string, productConfigId: string): Observable<IProductConfigApiResponse>

}

@Injectable({
  providedIn: "root",
})
export class Db3DApiBackendClient implements IDb3DApiBackendClient {

  constructor(
    private httpClient: HttpClient
  ) {}

  public getOtherDesignsResponse(domain:string, id:string): Observable<any> {
    return this.httpClient.get(`${domain}/api/product-design/${id}/other-designs/`);
  }

  public getUsersList(domain: string, queryParamsfromUrl?: QueryParamsForGetUsers): Observable<any> {
    let queryParams = new HttpParams();
    const newPage = queryParamsfromUrl.page ? queryParamsfromUrl.page : "1";
    queryParams = queryParams.set("page", newPage);
    queryParams = queryParams.set("page_size", 20);
    if (queryParamsfromUrl.search) queryParams = queryParams.set("search", queryParamsfromUrl.search);
    if (queryParamsfromUrl.ordering) queryParams = queryParams.set("ordering", queryParamsfromUrl.ordering);

    return this.httpClient.get(`${domain}/api/users/`, { params: queryParams });
  }

  public getUserDetailsData(domain: string, id: string): Observable<any> {
    return this.httpClient.get(`${domain}/api/users/${id}/`);
  }

  public getUserDetailsById(domain: string, id: string): Observable<any> {
    return this.httpClient.get(`${domain}/api/users/${id}/`);
  }

  public getUsersRolesList(domain: string, whiteLabelSlug: string): Observable<any> {
    return this.httpClient.get(`${domain}/api/user-roles/wl-slug/${whiteLabelSlug}/`);
  }

  public patchUsersRole(domain:string, id: string, payload: {"role": UsersRole}): Observable<any> {
    const url = `${domain}/api/users/${id}/`;
    return this.httpClient.patch<ResponseType>(url, payload);
  }

  public getBrandsVisibility(domain: string, whiteLabelSlug:string): Observable<any> {
    return this.httpClient.get(`${domain}/api/brands-visibility/${whiteLabelSlug}/`);
  }
  public patchBrandsVisibility(domain: string, whiteLabelSlug: string, payload: VisibleBrandsPayload): Observable<any> {
    return this.httpClient.patch<ResponseType>(`${domain}/api/brands-visibility/${whiteLabelSlug}/`, payload);
  }

  public getBrandsAvailability(domain: string, whiteLabelSlug:string): Observable<any> {
    return this.httpClient.get(`${domain}/api/brands-availability/${whiteLabelSlug}/`);
  }

  public getProductDesignSave(domain: string, httpBody: ProductDesignHttpBody): Observable<any> {
    return this.httpClient.post(`${domain}/api/product-design/`, httpBody );
  }

  public getProductsList(domain: string, qParams: string): Observable<any> {
    let productListUrl;
    productListUrl = `${domain}/api/products/`;
    productListUrl += qParams;
    
    return this.httpClient.get(`${productListUrl}`);
  }

  public getMyDesigns(domain: string, qParams: string): Observable<any> {
    let productListUrl;
    productListUrl = `${domain}/api/product-design/`;
    qParams += "&format=json";

    productListUrl += qParams;
    
    return this.httpClient.get(`${productListUrl}`);
  }

  public getOverwriteExistingDesign(domain: string, productDesignId: string, httpBody: ProductDesignHttpBody): Observable<any> {
    return this.httpClient.patch(`${domain}/api/product-design/${productDesignId}/`, httpBody );
  }

  public getProductDesign(domain: string, productDesignId: string): Observable<IProductDesignApiResponse> {
    return this.httpClient.get<IProductDesignApiResponse>(`${domain}/api/product-design/${productDesignId}/`);
  }

  public patchUserDetails(domain: string, userId: number, body: VerificationFormData): Observable<AuthPatchUserDetailsData> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("Content-Type", "application/json");
    const url: string = `${domain}/api/user/${userId}/`;
    return this.httpClient.patch<AuthPatchUserDetailsData>(url, body, {headers: httpHeaders});
  }
  
  public patchUsersDetailsEditMode(domain:string, id: string, payload: UpdateUserPayload ): Observable<any> {
    const url = `${domain}/api/users/${id}/`;
    return this.httpClient.patch<ResponseType>(url, payload);
  }

  public getUserDetails(domain: string): Observable<ICurrentUserDetails> {
    return this.httpClient.get<ICurrentUserDetails>(`${domain}/api/user/details/`);
  }

  public getUserPermissions(domain: string): Observable<any> {
    return this.httpClient.get(`${domain}/api/user/permissions/`);
  }

  public checkPasswordResetToken(domain: string, httpBody: {user_id: string, token: string}): Observable<any> {
    return this.httpClient.post(`${domain}/api/user/check-password-reset-token/`, httpBody);
  }

  public shareDesign(domain: string, designUuid: string, payload: IShareDialogPayload): Observable<any> {
    return this.httpClient.post(`${domain}/api/product-design/${designUuid}/share-to-email/`, payload);
  }

  public passwordResetConfirm(domain: string, httpBody: {user_id: string, token: string, password: string}): Observable<any> {
    return this.httpClient.post(`${domain}/api/user/password-reset-confirm/`, httpBody);
  }

  public passwordReset(domain: string, httpBody: {email: string, wl_slug: string}): Observable<any> {
    return this.httpClient.post(`${domain}/api/user/password-reset/`, httpBody);
  }

  public userRegistration(domain: string, httpBody: {password: string, email: string, wl_slug: string}): Observable<AuthRegistrationData> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("Content-Type", "application/json");
    return this.httpClient.post<AuthRegistrationData>(`${domain}/api/user/`, httpBody, {headers: httpHeaders});
  }

  public userLoginWithToken(domain: string, httpBody: {token: string}): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("Content-Type", "application/json");
    return this.httpClient.post(`${domain}/api/login/magic-link/`, httpBody, {headers: httpHeaders});
  }

  public userLogin(domain: string, httpBody: {email: string, password: string, api: string, app: string, wl_slug: string}): Observable<AuthResponseData> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("Content-Type", "application/json");
    return this.httpClient.post<AuthResponseData>(`${domain}/api/auth/login/`, httpBody, {headers: httpHeaders});
  }

  public updateUserInfo(domain: string, designerId: number): Observable<UserResponseData> {
    return this.httpClient.get<UserResponseData>(`${domain}/api/designer/${designerId}/`);
  }

  public getWhiteLabelSetupConfiguration(domain: string, whitelabelSlug: string): Observable<IWhiteLabelSetupConfigurationApiResponse> {
    return this.httpClient.get<IWhiteLabelSetupConfigurationApiResponse>(`${domain}/api/wl/${whitelabelSlug}/`);
  }

  public updateWhiteLabelLogo(domain: string, whitelabelSlug: string, formData: FormData): Observable<IWhiteLabelSetupConfigurationApiResponse> {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    return this.httpClient.patch<IWhiteLabelSetupConfigurationApiResponse>(
      `${domain}/api/wl/${whitelabelSlug}/`,
      formData,
      { headers: headers }
    );
  }

  public updateShowPrices(domain: string, whitelabelSlug: string, payload: ShowPrices): Observable<IWhiteLabelSetupConfigurationApiResponse> {
    return this.httpClient.patch<IWhiteLabelSetupConfigurationApiResponse>(
      `${domain}/api/wl/${whitelabelSlug}/`,
      payload
    );
  }
  
  public updatePDFTemplate(domain: string, whitelabelSlug: string, payload: { pdf_template: string }): Observable<IWhiteLabelSetupConfigurationApiResponse> {
    return this.httpClient.patch<IWhiteLabelSetupConfigurationApiResponse>(
      `${domain}/api/wl/${whitelabelSlug}/`,
      payload
    );
  }

  public updateUserDetails(domain: string, userId: number, body: FormData): Observable<any> {
    const url: string = `${domain}/api/users/${userId}/`;
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    return this.httpClient.patch<AuthPatchUserDetailsData>(url, body, { headers: headers });
  }
  
  public getProductConfig(domain: string, productConfigId: string): Observable<IProductConfigApiResponse> {
    return this.httpClient.get<IProductConfigApiResponse>(`${domain}/api/products/${productConfigId}/detail/`);
  }

}