import { Injectable } from "@angular/core";
import { ICurrentUserDetails } from "./user-details.service";

@Injectable({providedIn: "root"})
export class VerificationValidatorService {
  constructor() {}

  public validate(twoStepRegistration: boolean, currentUserDetails: ICurrentUserDetails): boolean {
    if (twoStepRegistration) return !!(currentUserDetails.name || currentUserDetails.company || currentUserDetails.job_position);
    else return true;    
  }
}