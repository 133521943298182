import { Component, OnDestroy, OnInit, ViewChild, ElementRef, HostListener, ChangeDetectorRef } from "@angular/core";
import { NavbarService } from "../../services/navbar.service";
import {FooterService } from "../../services/footer.service";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { ProductDesignFactory } from "../../factories/product-design-factory";
import { ProductDesign } from "../../data-models/product-design";
import { IntiaroAnalyticsClient } from "../../services/analytics/intiaro-analytics.service";
import { UserService } from "../../services/authentication/user.service";
import { ProductDetails } from "../../data-models/types";
import { RedirectService } from "../../services/redirect.service";
import { ProductConfigComponent } from "../../components/product-config/product-config.component";
import { Subject } from "rxjs";
import { OtherDesignsFactory } from "src/app/factories/other-designs-factory";
import { OtherDesign } from "src/app/data-models/other-designs/other-designs";
import { Db3DApiBackendClient } from "src/app/services/api/db3d-api-backend-client.service";
import { trigger, transition, style, animate } from "@angular/animations";
import { NavigationService } from "src/app/services/NavigationService.service";

declare var clearIntiaroInstances;

@Component({
  selector: "app-product-design-preview",
  templateUrl: "./product-design-preview.component.html",
  styleUrls: ["./product-design-preview.component.scss"],
  animations: [
      trigger("productDetailsInOut", [
          transition("void => *", [
              style({opacity: "0"}),
              animate(250)
          ]),
          transition("* => void", [
              animate(200, style({opacity: "0"}))
          ])
      ]),
    ]
})
export class ProductDesignPreviewComponent implements OnInit, OnDestroy {
  public productDesignId: string;
  public productDesign: ProductDesign;
  public environment = environment;
  public designDetails: ProductDetails | undefined;
  private productDesignFactory: ProductDesignFactory = new ProductDesignFactory();
  private otherDesignsFactory: OtherDesignsFactory = new OtherDesignsFactory();
  public otherDesignsSubject:Subject<OtherDesign[]> = new Subject<OtherDesign[]>();
  public showProductDetailsFlag: boolean = false;
  public viewMobile: boolean;

  readonly viewInitAnalyticsEventName = "previewProductDesignInitiated";
  public readonly SCREEN_WIDTH_BREAKPOINT = 900; //this value should be coherent with breakpoint in scss
  @ViewChild("flexContainer") flexContainer: ElementRef;

  @HostListener("window:resize", ["$event"])
  onResize(event: any): void {
    this.setViewMobile(event.target.innerWidth);
  }

  constructor(
    private navbarService: NavbarService,
    private footerService: FooterService,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private router: Router,
    private intiaroAnalyticsClient: IntiaroAnalyticsClient,
    private userService: UserService,
    private changeDetector: ChangeDetectorRef,
    private db3DApiBackendClient: Db3DApiBackendClient,
    public navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.setViewMobile(window.innerWidth);
    this.navbarService.hide();
    this.footerService.hide();
    this.productDesignId = this.activatedRoute.snapshot.paramMap.get("id");
    this.getProductDesignData();
    this.sendAnalyticsData();
  }

  private sendAnalyticsData(): void{

    this.intiaroAnalyticsClient.sendEvent(
      "previewProductDesignInitiated",
      {
        id: this.productDesignId
      }
    );

  }

  public onOtherDesignItemClick(otherDesign: OtherDesign): void {
    const link:string = `product-design/${otherDesign.id}/preview`;
    window.location.href = link;
  }

  ngOnDestroy(): void {
    clearIntiaroInstances();
    this.navbarService.show();
    this.footerService.show();
  }

  async getProductDesignData() {
    const productDesignDetailApiUrl = `${environment.db3dBackendDomain}/api/product-design/${this.productDesignId}/`;
    let productDesignData: any;

    try {
      productDesignData = await this.httpClient.get<any>(productDesignDetailApiUrl).toPromise();
    } catch(err) {
      if(err.status === 404) this.router.navigate([this.navigationService.pageNotFound]);      
      return;
    }
    if(productDesignData !== undefined) {
      this.productDesign = this.productDesignFactory.createFromBackendApi(productDesignData);
      this.setProductDetailsData(
        this.productDesign.productName,
        this.productDesign.brand_name,
        this.productDesign.productVersionName,
        this.productDesign.categories
      );
      this.getOtherDesignsList(this.environment.db3dBackendDomain);
    }
  }

  private getOtherDesignsList(domain:string):void {
    this.db3DApiBackendClient.getOtherDesignsResponse(domain, this.productDesign.id)
    .subscribe((response:any):void => this.buildOtherDesigns(response.items));
  }

  private buildOtherDesigns(apiResponseItems:any[]):void {

    const otherDesignsItems:OtherDesign[] = [];
    for (const item of apiResponseItems) {
      otherDesignsItems.push(this.otherDesignsFactory.createFromApiResponse(item));
    }
    this.otherDesignsSubject.next(otherDesignsItems);
  }

  public goToDesignDetails() {

    this.intiaroAnalyticsClient.sendEvent(
      "customiseDesignButtonClickedInProductDesignPreview",
      {
        id: this.productDesignId,
      }
    );

    const user = this.userService.getCurrentUser()
    if(user && user.isAuthenticated) {
      this.router.navigate(
        [this.navigationService.productList, this.productDesign.productId],
        {queryParams: {
          [ProductConfigComponent.queryParamsKeyCustomConfigId]: this.productDesign.customConfigurationId,
        }}
      );
    } else {
      this.router.navigate(
        [this.navigationService.login],
        {queryParams: {
          [RedirectService.queryParamKey]: ProductConfigComponent.getPathToCustomisedProduct(this.productDesign.productId.toString(), this.productDesign.customConfigurationId),
        }}
      )
    }
  }

  setProductDetailsData(productName: string, brandName: string, programName: string, categories: string): void {
    this.designDetails = {
      productName: productName,
      brandName: brandName,
      programName: programName,
      categories: categories
    };
  }

  private setViewMobile(screenWidth: number): void {
    if(screenWidth > this.SCREEN_WIDTH_BREAKPOINT) {
      this.showProductDetailsFlag = true;
      this.viewMobile = false;
    } else {
      this.showProductDetailsFlag = false;
      this.viewMobile = true;
    }
    this.changeDetector.detectChanges()
  }

  public showProductDetails(): void {
    this.showProductDetailsFlag = !this.showProductDetailsFlag;
  }
}
