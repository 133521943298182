import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { AvatarService } from "src/app/services/avatar.service";

@Component({
  selector: "app-choose-file-button",
  templateUrl: "./choose-file-button.component.html",
  styleUrls: ["./choose-file-button.component.scss"]
})

export class ChooseFileButtonComponent implements AfterViewInit {
  public errorMessage: string;

  @ViewChild("chooseFileInput") chooseFileInput: ElementRef;

  @Input() buttonText: string = "Choose file...";
  @Input() disclaimer: string;
  @Output() newAvatarFileChoosen = new EventEmitter<File>();

  constructor(
    public avatarService: AvatarService
  ) {}

  ngAfterViewInit(): void {
    this.chooseFileInput.nativeElement.addEventListener("input", (event) => {
      this.uploadFile(event);
    });
  }

  public async uploadFile(event: Event): Promise<void> {
    this.errorMessage = "";
    const fileUpload = event.target as HTMLInputElement;
    if (fileUpload.files.length === 1) {
      const newAvatarFile: File = fileUpload.files[0];
      const result = await this.avatarService.validateFile(newAvatarFile);
      if(result.valid) {
        this.newAvatarFileChoosen.emit(newAvatarFile);
      } else {
        this.errorMessage = result.error;
        this.chooseFileInput.nativeElement.value = "";
      }
    }
  }
}
