import { Brand } from "../data-models/manage-brands/brand.model";

export interface IBrandApiResponse {
  name: string,
  slug: string
}

export class BrandFactory {
  static createFromBackendApi(apiResponse: IBrandApiResponse): Brand {
    return new Brand(apiResponse.name, apiResponse.slug);
  }
}
