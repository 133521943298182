import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "checkbox-with-label",
  templateUrl: "./checkbox-with-label.component.html",
  styleUrls: ["./checkbox-with-label.component.scss"]
})

export class CheckboxWithLabelComponent {

  @Input() labelText: string;
  @Input() checkboxControl: boolean;
  //if leftSideStyle === false, checkbox on the left side from label; if leftSideStyle === false, checkbox on the right side
  @Input() leftSideStyle: boolean = false;
  @Output() onCheckboxStateChange = new EventEmitter<boolean>();

  constructor() {
  }

  public onChange(event: Event): void {
    const checkboxBrand = event.target as HTMLInputElement;
    this.onCheckboxStateChange.emit(checkboxBrand.checked);
  }
}
