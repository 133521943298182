import { Component, Input } from "@angular/core";
import { Subject } from "rxjs";

@Component({
  selector: "app-simple-select-option",
  templateUrl: "./simple-select-option.component.html",
  styleUrls: ["./simple-select-option.component.scss"]
})
export class SimpleSelectOptionComponent {

  @Input() value: string;
  @Input() abbreviation: string;

  public checked: boolean = false;
  public onChangeSubject: Subject<any>;
  
  public onChange(): void {
    Promise.resolve().then(() => {
      this.onChangeSubject.next(this.value);      
    });
  }

}

