import {ProductConfig} from '../data-models/product-config';

export interface IProductConfigApiResponse {
  brand_name: string;
  categories: {name: string}[];
  id: string;
  name: string;
  product_version: string;
  product_version_name: string;
  web_360: boolean;
  pim_tenant_id: string;
  pim_context?: string;
  save_and_render_strategy?: string;
}

export class ProductConfigFactory {

  createFromBackendApi(apiResponse: IProductConfigApiResponse) {
    return new ProductConfig(
      apiResponse.brand_name,
      apiResponse.categories,
      apiResponse.id,
      apiResponse.name,
      apiResponse.product_version,
      apiResponse.product_version_name,
      apiResponse.web_360,
      apiResponse.pim_tenant_id,
      apiResponse.pim_context,
      apiResponse.save_and_render_strategy
    );
  }

}

