import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavbarService } from '../../services/navbar.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { PopupService } from '../../services/popup.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ImageExportPopupComponent } from '../image-export-popup/image-export-popup.component';
import { environment } from '../../../environments/environment';
import { IntiaroAnalyticsClient } from '../../services/analytics/intiaro-analytics.service';
import { FullFunctionalityDialogComponent } from './full-functionality-dialog/full-functionality-dialog.component';
import { FooterService } from '../../services/footer.service';
import { NavigationService } from 'src/app/services/NavigationService.service';


declare var getIntiaroPlayer: any;


@Component({
    selector: 'product-design-demo',
    templateUrl: './product-design-demo.component.html',
    styleUrls: ['./product-design-demo.component.scss']
})
export class ProductDesignDemoComponent implements OnInit, OnDestroy {
    public productDesignId: string;
    public productDesignData;
    public gettingStartedVideoUrl = 'https://www.youtube.com/watch?v=Njm1jh8cs8Y';
    public player360CustomerId = environment.player360CustomerId;
    private defaultPdfSpecsheetImageAngles = [0, 30, 90];

    constructor(
        private activatedRoute: ActivatedRoute,
        private navbarService: NavbarService,
        private footerService: FooterService,
        private httpClient: HttpClient,
        private popupService: PopupService,
        private dialog: MatDialog,
        private intiaroAnalyticsClient: IntiaroAnalyticsClient,
        public navigationService: NavigationService
    ) {
    }

    async ngOnInit() {
        this.intiaroAnalyticsClient.sendEvent(
            'demoProductPageViewed',
            {id: this.productDesignId}
        );

        this.navbarService.hide();
        this.footerService.hide();
        this.productDesignId = this.activatedRoute.snapshot.paramMap.get('id');
        await this.getProductDesignData();
    }

    ngOnDestroy() {
        this.navbarService.show();
        this.footerService.show();
    }

    async getProductDesignData() {
        const productDesignDetailApiUrl = `${environment.db3dBackendDomain}/api/product-design/${this.productDesignId}/`;

        try {
            this.productDesignData = await this.httpClient.get<any>(productDesignDetailApiUrl).toPromise();
        } catch (error) {
            console.log('could not download product description');
        }
    }

    getIntiaroConfiguratorInstance() {
        return getIntiaroPlayer('product-design-configurator');
    }

    onPdfFileReady(url: string) {
        saveAs(url, `${this.productDesignId}_summary.pdf`);
        this.intiaroAnalyticsClient.sendEvent(
            'demoProductPageCreatePdfSpecsheetReady',
            {id: this.productDesignId, name: this.productDesignData.name, value: url}
        );
        this.popupService.hidePopup();
        // let httpHeaders = new HttpHeaders();
        // httpHeaders = httpHeaders.set('Accept', 'application/pdf');
        // httpHeaders = httpHeaders.set('Require-Auth', 'false');
        //
        // this.httpClient.get(url, {headers: httpHeaders, responseType: 'blob'}).subscribe(
        //     (file) => {
        //         this.popupService.hidePopup();
        //         this.intiaroAnalyticsClient.sendEvent(
        //             'demoProductPageCreatePdfSpecsheetReady',
        //             {id: this.productDesignId, name: this.productDesignData.name, value: url}
        //         );
        //         saveAs(file, `${this.productDesignId}_summary.pdf`);
        //     },
        //     (error) => {
        //         console.log(error);
        //     }
        // );
    }

    onPdfFileFail() {
        this.intiaroAnalyticsClient.sendEvent(
            'demoProductPageCreatePdfSpecsheetFailed',
            {id: this.productDesignId, name: this.productDesignData.name}
        );
        this.openPdfGenerateFailPopUp();
    }

    openPdfGenerateFailPopUp() {
        const options = {
            title: 'Download PDF',
            text: [
                'Your request could not be completed. Please try again later.',
            ],
            buttons: [
                {
                    text: 'Close',
                    callback: () => {
                        this.popupService.hidePopup();
                    }
                }
            ]
        };

        this.popupService.showPopup(options);
    }

    createPdfGenerationInProgressPopup() {
        const options = {
            title: 'Please wait',
            text: [
                'Your request is being processed. Please wait.',
            ]
        };

        this.popupService.showPopup(options);
    }

    generatePdfSpecsheet() {
        this.intiaroAnalyticsClient.sendEvent(
            'demoProductPageCreatePdfSpecsheetClicked',
            {id: this.productDesignId, name: this.productDesignData.name}
        );
        this.createPdfGenerationInProgressPopup();
        const intiaroConfigurator = this.getIntiaroConfiguratorInstance();
        intiaroConfigurator.getProductSummaryUrl(
            this.defaultPdfSpecsheetImageAngles,
            (url) => {
                this.onPdfFileReady(url);
            },
            () => {
                this.onPdfFileFail();
            }
        );
    }

    createImageExportPopup() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '1280px';
        dialogConfig.height = '720px';
        dialogConfig.panelClass = 'db3d-popup-wrapper';
        dialogConfig.data = {
            support360: true
        };

        return this.dialog.open(ImageExportPopupComponent, dialogConfig);
    }

    generateImage() {
        const dialogRef = this.createImageExportPopup();

        const intiaroConfigurator = this.getIntiaroConfiguratorInstance();
        const angle = intiaroConfigurator.getAngle();
        const imageUrl = intiaroConfigurator.getFrameUrl(angle);

        this.intiaroAnalyticsClient.sendEvent(
            'demoProductPageExportImageClicked',
            {id: this.productDesignId, name: this.productDesignData.name, value: imageUrl}
        );

        if (dialogRef.componentInstance) {
            dialogRef.componentInstance.product = {
                status: 'success',
                image_url: imageUrl
            };
        }
    }

    createFullFunctionalityDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '600px';
        dialogConfig.height = '320px';
        dialogConfig.panelClass = 'db3d-popup-wrapper';
        dialogConfig.data = {
            productDesignUUID: this.productDesignId
        };

        this.dialog.open(FullFunctionalityDialogComponent, dialogConfig);
    }

    generate3dModel() {
        this.intiaroAnalyticsClient.sendEvent(
            'demoProductPageGenerate3dModelClicked',
            {id: this.productDesignId, name: this.productDesignData.name}
        );
        this.createFullFunctionalityDialog();
    }

    saveProductDesign() {
        this.intiaroAnalyticsClient.sendEvent(
            'demoProductPagesaveProductDesignClicked',
            {id: this.productDesignId, name: this.productDesignData.name}
        );
        this.createFullFunctionalityDialog();
    }
}
