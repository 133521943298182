import {Component, Input, OnInit, AfterViewInit, AfterViewChecked, OnDestroy, ViewChild, ElementRef, HostListener} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {OtherDesign} from 'src/app/data-models/other-designs/other-designs';
import { IntiaroAnalyticsClient } from 'src/app/services/analytics/intiaro-analytics.service';
import { ProductDesignPreviewComponent } from 'src/app/components/product-design-preview/product-design-preview.component';

@Component({
  selector: 'app-other-designs',
  templateUrl: './other-designs.component.html',
  styleUrls: ['./other-designs.component.scss']
})

export class OtherDesignsComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

  private itemsObservableSubscription: Subscription;
  public items:OtherDesign[] = [];
  private readonly SLIDER_JUMP_MIN_VALUE = 170;
  public sliderView: boolean = false;

  @Input() label: string = "Other Designs";
  @Input() itemsObservable: Subject<OtherDesign[]>;
  @Input() callToActionFunction: (otherDesign:OtherDesign)=>void;

  @ViewChild('sliderItemsContainer') sliderItemsContainer: ElementRef;
  @ViewChild('chevronLeft') chevronLeft: ElementRef;
  @ViewChild('chevronRight') chevronRight: ElementRef;

  @HostListener('window:resize', ['$event'])
  @HostListener('window:orientationchange', ['$event'])
  onWindowEvents(event: any): void {
    this.setSliderView(event.target.innerWidth);
  }

  constructor(
    private intiaroAnalyticsClient: IntiaroAnalyticsClient,
    private productDesignPreview: ProductDesignPreviewComponent
  ){}

  ngOnInit():void {
    this.setSliderView(window.innerWidth);
  }

  ngAfterViewInit(): void{
    this.startObservableItems();
  }

  ngAfterViewChecked(): void {
    if( this.isChevronControlVisible() ) this.setChevronsVisibility();
  }

  public goToDesign(productDesign:OtherDesign):void{
    this.intiaroAnalyticsClient.sendEvent(
      'otherDesignItemClicked',
      {
        id: productDesign.id
      },
      true
    );

    this.callToActionFunction(productDesign);
  }

  private startObservableItems(): void{
    this.itemsObservableSubscription = this.itemsObservable.subscribe((items:OtherDesign[])=>{
      this.items = items;
    })
  }

  ngOnDestroy():void{
    this.itemsObservableSubscription.unsubscribe();

    ['touchstart', 'touchmove', 'touchend'].forEach(eventName => {
      this.sliderItemsContainer.nativeElement.removeEventListener(eventName);
    })
  }

  public shortenName(name: string): string {
    if (name.length > 30) return name.slice(0, 30) + "...";
    return name
  }

  private setSliderView(screenWidth: number): void {
    (screenWidth > this.productDesignPreview.SCREEN_WIDTH_BREAKPOINT)
    ? this.sliderView = false
    : this.sliderView = true;
  }

  private calculateSliderJumpValue(): number {
    const jumpIndicator= 4 ;
    const screenDependentSliderJumpValue = this.sliderItemsContainer.nativeElement.clientWidth / jumpIndicator;
    if(screenDependentSliderJumpValue > this.SLIDER_JUMP_MIN_VALUE) {
      return screenDependentSliderJumpValue;
    } else {
      return this.SLIDER_JUMP_MIN_VALUE;
    }
  }

  public goToPrev(): void {
    const scrollLeftValue: number = this.sliderItemsContainer.nativeElement.scrollLeft;
    this.sliderItemsContainer.nativeElement.scrollLeft = scrollLeftValue - this.calculateSliderJumpValue();
  }

  public goToNext(): void {
    const scrollRightValue: number = this.sliderItemsContainer.nativeElement.scrollLeft;
    this.sliderItemsContainer.nativeElement.scrollLeft = scrollRightValue + this.calculateSliderJumpValue();
  }

  private setChevronsVisibility(): void {
    this.setLeftChevronVisibility();
    this.setRightChevronVisibility();
  }

  private setLeftChevronVisibility(): void {
    const scrollLeftValue: number = this.sliderItemsContainer.nativeElement.scrollLeft;
    if(scrollLeftValue <= 0) {
      this.chevronLeft.nativeElement.classList.add('hideChevron')
    } else {
      this.chevronLeft.nativeElement.classList.remove('hideChevron');
    }  }

  private setRightChevronVisibility(): void {
    const scrollLeftValue: number = this.sliderItemsContainer.nativeElement.scrollLeft;
    const clientWidthValue: number = this.sliderItemsContainer.nativeElement.clientWidth;
    const scrollWidthValue: number = this.sliderItemsContainer.nativeElement.scrollWidth;
    if( (scrollLeftValue + clientWidthValue) >= scrollWidthValue ) {
      this.chevronRight.nativeElement.classList.add('hideChevron');
    } else {
      this.chevronRight.nativeElement.classList.remove('hideChevron');
    }
  }

  public isChevronControlVisible(): boolean {
    return (this.items.length > 2);
  }
}
