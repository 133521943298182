import { Component, Input } from "@angular/core";

type linkBtnConfig = {
  text: string;
  iconPath: string;
}

@Component({
  selector: "app-copy-link-button",
  templateUrl: "./copy-link-button.component.html",
  styleUrls: ["./copy-link-button.component.scss"]
})
export class CopyLinkButtonComponent {
  private readonly linkButtonStateTimeout = 5000;
  private readonly linkButtonInitialState: linkBtnConfig = {
    text: "Copy link to Design",
    iconPath: "assets/images/icons/copy_link.svg"
  };
  private readonly linkButtonCopiedState: linkBtnConfig = {
    text: "Link copied",
    iconPath: "assets/images/icons/arrow_rotate_left.svg"
  };

  public linkButtonText = this.linkButtonInitialState.text;
  public linkButtonIconPath = this.linkButtonInitialState.iconPath;

  @Input() customProductUrl: string;

  constructor() {}

  onCopyLinkButtonClicked(): void {
    if(this.linkButtonText === this.linkButtonInitialState.text) {
      this.linkButtonIconPath = this.linkButtonCopiedState.iconPath;
      this.linkButtonText = this.linkButtonCopiedState.text;
      setTimeout(( () => {
        this.linkButtonIconPath = this.linkButtonInitialState.iconPath;
        this.linkButtonText = this.linkButtonInitialState.text;
      }), this.linkButtonStateTimeout);
    }
  }

}
