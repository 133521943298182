import {UserService} from "../../authentication/user.service";
import {ActivatedRouteSnapshot, Router, UrlTree} from "@angular/router";
import {IGuardMixin} from "./IGuardMixin";
import { NavigationService } from "../../NavigationService.service";

export class LoggedGuardMixin implements IGuardMixin {

  constructor(private userService: UserService) {
  }

  getRedirectUrlTree(router: Router, _route: ActivatedRouteSnapshot): UrlTree {
    return router.createUrlTree([NavigationService.create().login]);
  }

  public evaluate(_route: ActivatedRouteSnapshot): boolean {
    return this.userService.getCurrentUser() !== null;
  }
}
