/* eslint-disable curly */
import { Component, Input, OnInit } from "@angular/core";

export type Attribute = {
  attribute_group: string;
  name: string;
  slug: string;
  choice: {
    name: string;
    slug: string;
  }
}

export type ElementOption = {
  attributes: Array<Attribute>;
  id: string;
  instance_id: string;
  name: string;
}

export type ProductOptions = {
  elements: Array<ElementOption>;
}

@Component({
  selector: "app-configuration-details",
  templateUrl: "./configuration-details.component.html",
  styleUrls: ["./configuration-details.component.scss"]
})
export class ConfigurationtDetailsComponent implements OnInit {

  @Input() configurationDetails: any;
  public config: Array<ElementOption>;
  public showSpinner: boolean = true;

  constructor() {}

  ngOnInit() {
    this.configurationDetails.subscribe((config: ProductOptions) => {
      this.showSpinner = !(config.elements.length > 0);
      this.config = config.elements;
    });
  }
}
