import { UsersRole } from "./user-role.model";

export class UserDetails {
  constructor(
    public analyticsAttributes: Array<any>,
    public id: number,
    public name: string,
    public email: string,
    public company: string,
    public phoneNumber: string,
    public portfolioUrl: string,
    public status: string,
    public role: UsersRole,
    public dateJoined: string,
    public jobPosition: string,
    public profileImagePath: string,
  ) {}
}
