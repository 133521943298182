import { Component, ElementRef, Input, ViewChild } from "@angular/core";

@Component({
  selector: "app-contact-box",
  templateUrl: "./contact-box.component.html",
  styleUrls: ["./contact-box.component.scss"]
})

export class ContactBoxComponent {
  @ViewChild("contactBoxButton") toggleBtn: ElementRef;
  @Input() name: string;
  @Input() phone: string;
  @Input() email: string;
  @Input() profileImage: string;
  @Input() previewMode?: boolean = false;

  public isContactDataVisible: boolean = false;

  public toggleDetailsVisibility(): void {
    if(this.toggleBtn.nativeElement.classList.contains("arrow-rotation")) {
      this.toggleBtn.nativeElement.classList.remove("arrow-rotation");
      this.isContactDataVisible = false;
    } else {
      this.toggleBtn.nativeElement.classList.add("arrow-rotation");
      this.isContactDataVisible = true;
    }
  }
}
