import {ElementsPrice, ListPrice, Price} from "../data-models/price.model";

export interface IPriceApiResponse {
  elements_price: Array<ElementsPrice>;
  list_price: ListPrice;
}

export class PriceFactory {
  static createFromBackendApi(apiResponse: IPriceApiResponse): Price {
    return new Price(
      apiResponse.elements_price,
      apiResponse.list_price
    );
  }
}
