/* eslint-disable curly */
/* eslint-disable arrow-body-style */
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "./auth.service";
import { GoogleTagManagerHandlerService } from "./google-tag-manager.service";
import * as Sentry from "@sentry/angular";
import { environment } from "../../environments/environment";
import { UserService } from "./authentication/user.service";
import { Db3DApiBackendClient } from "./api/db3d-api-backend-client.service";
import { AuthPatchUserDetailsData } from "../data-models/auth-response-data.model";

export interface ICurrentUserDetails {
  analytics_attributes: any[]
  company: string
  date_joined: string
  email: string
  id: number
  initials?: string
  name: string
  job_position: string
  phone_number: string
  portfolio_url: string
  role: any,
  permissions?: string[],
  profile_image?: string
}

@Injectable({ providedIn: "root" })
export class UserDetailsService {

  public currentUserDetails: BehaviorSubject<ICurrentUserDetails> = new BehaviorSubject(null);

  constructor(
    private authService: AuthService,
    private googleTagManagerHandlerService: GoogleTagManagerHandlerService,
    private userService: UserService,
    private db3DApiBackendClient: Db3DApiBackendClient
  ) {}

  public initialize(): void {
    this.subscribeToLoggedIn();
  }

  private subscribeToLoggedIn(): void {
    this.authService.loggedIn.subscribe((value) => this.onLoginStatusChanged(value));
  }

  private onLoginStatusChanged(isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.getCurrentUserDetails();
    } else {
      this.currentUserDetails.next(null);
    }
  }

  public getCurrentUserDetails(): void {

    this.db3DApiBackendClient.getUserDetails(environment.db3dBackendDomain).subscribe({
      next: (response: ICurrentUserDetails) => {
        const currentUser = this.userService.getCurrentUser();
        currentUser.analytics_attributes = response.analytics_attributes;

        if(JSON.stringify(currentUser.permissions) !== JSON.stringify(response.permissions)) {
          currentUser.permissions = response.permissions;
          localStorage.setItem(this.authService.userKey, JSON.stringify(currentUser));
        }

        this.currentUserDetails.next(response);
        this.setUserDimensions(response.analytics_attributes, currentUser.uuid);
      },
      error: (error) => {
        // temrporay solution
        if (error.status === 403) {
          this.authService.logout();
        }
        Sentry.captureMessage("Could not get user details");
      }
    });
  }

  private setUserDimensions(dimensionsArray: Array<any>, _userUuid: string) {
    if (!dimensionsArray) return;

    const designerDimensions = {};

    dimensionsArray.forEach((dimension) => {
      designerDimensions[dimension.key] = dimension.value;
    });

    this.googleTagManagerHandlerService.sendEvent("setDesignerDimensions", designerDimensions);
  }

  public updateCurrentUser(newData) {
    this.currentUserDetails.next(newData);
  }
}
