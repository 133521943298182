export type Category = {
  name: string;
};

export class ProductConfig {

  constructor(
    public brand_name: string,
    public categories: Array<Category>,
    public id: string,
    public name: string,
    public productVersion: string,
    public productVersionName: string,
    public web360: boolean,
    public pim_tenant_id: string,
    public pim_context?: string,
    public saveAndRenderStrategy?: string
  ) {
    this.brand_name = brand_name;
    this.categories = categories;
    this.id = id;
    this.name = name;
    this.productVersion = productVersion;
    this.productVersionName = productVersionName;
    this.web360 = web360;
    this.pim_tenant_id = pim_tenant_id;
    this.pim_context = pim_context;
    this.saveAndRenderStrategy = saveAndRenderStrategy;
  }
}
