import { Component, OnInit } from '@angular/core';
import { Db3dSelectComponent } from '../../db3d-select/db3d-select.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-brands-filter',
  templateUrl: './brands-filter.component.html',
  styleUrls: ['../../db3d-select/db3d-select.component.scss']
})
export class BrandsFilterComponent extends Db3dSelectComponent implements OnInit {

  constructor(activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

}
