import { Component, OnInit } from "@angular/core";
import { MyProfileAbstractComponent } from "../../my-profile-abstract.component";
import { Router } from "@angular/router";
import { Db3DApiBackendClient } from "src/app/services/api/db3d-api-backend-client.service";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";
import { NavigationService, RaoutesRegistry, OutletsRegistry } from "src/app/services/NavigationService.service";
import { UserDetailsService } from "src/app/services/user-details.service";

@Component({
  selector: "app-basic-info-display",
  templateUrl: "./basic-info-display.component.html",
  styleUrls: ["../../my-profile-subcomponents.component.scss"],
})

export class BasicInfoDisplayComponent extends MyProfileAbstractComponent implements OnInit {

  constructor(
    db3DApiBackendClient: Db3DApiBackendClient,
    wlConfigService: WhiteLabelConfigurationService,
    userDetailsService: UserDetailsService,
    private navigationService: NavigationService,
    private router: Router,
  ) {
    super(db3DApiBackendClient, wlConfigService, userDetailsService);
  }

  editBasicInfo() {
    this.router.navigate([this.navigationService.myProfile, { outlets: { [OutletsRegistry.basicInfo]: [RaoutesRegistry.basicInfoEdit] } }]);
  }
}
