import {ProductDesign} from "../data-models/product-design";
import { IUserDetailsApiResponse } from "./user-details-factory";

export interface IProductDesignApiResponse {
  brand_name: string;
  categories: string;
  custom_configuration_id: string;
  id: string;
  name: string;
  product_id: number; // intiaro_model_id
  product_name: string;
  product_thumbnail: string;
  product_version: string;
  product_version_name: string;
  web_360: boolean;
  pim_tenant_id?: string;
  pim_context?: string;
  save_and_render_strategy?: string;
  owner?: IUserDetailsApiResponse;
}

export class ProductDesignFactory {
  public createFromBackendApi(apiResponse: IProductDesignApiResponse): ProductDesign {
    return new ProductDesign(
      apiResponse.id,
      apiResponse.name,
      apiResponse.categories,
      apiResponse.brand_name,
      apiResponse.custom_configuration_id,
      apiResponse.product_id,
      apiResponse.product_name,
      apiResponse.product_thumbnail,
      apiResponse.product_version,
      apiResponse.product_version_name,
      apiResponse.web_360,
      apiResponse.pim_tenant_id,
      apiResponse.pim_context,
      apiResponse.save_and_render_strategy,
      apiResponse.owner
    );
  }
}
