/* eslint-disable arrow-body-style */
/* eslint-disable curly */
import { Component, OnInit } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Db3DApiBackendClient } from "src/app/services/api/db3d-api-backend-client.service";
import { BrandFactory } from "src/app/factories/brand-factory";
import { Brand } from "src/app/data-models/manage-brands/brand.model";
import { UsersDataFetchMessages } from "src/app/data-models/manage-users/users-data-fetch-messages";
import { WhiteLabelContextService } from "src/app/services/white-labels/white-label-context.service";
import { environment } from "src/environments/environment";

export type VisibleBrandsPayload = {"visible_brands": Brand[]};
@Component({
  selector: "app-brands-visibility-form",
  templateUrl: "./brands-visibility-form.component.html",
  styleUrls: ["./brands-visibility-form.component.scss"]
})
export class BrandsVisibilityFormComponent implements OnInit {

  public visibleBrands: Brand[] = [];
  public availabilityBrands: Brand[] = [];
  public errorMessage: string = "";

  constructor(
    private whiteLabelContextService: WhiteLabelContextService,
    private db3DApiBackendClient: Db3DApiBackendClient
  ) {}

  public isBrandChecked(brand: Brand): boolean {
    return !! this.visibleBrands.find((itemBrand: Brand) => itemBrand.slug === brand.slug);
  }

  public readonly message: string = UsersDataFetchMessages.loading;

  ngOnInit(): void {
    this.loadBrands();
  }

  public loadBrands(): void {
    const createBrandDTO: (obj: any)=> Brand[] = (obj: any) => {
      return obj.map((brand: Brand) => BrandFactory.createFromBackendApi(brand));
    };

    this.db3DApiBackendClient.getBrandsVisibility(environment.db3dBackendDomain, this.whiteLabelContextService.getCurrentWhiteLabelSlug()).subscribe({
      next:(apiResponse: any) => {
        this.visibleBrands = createBrandDTO(apiResponse.visible_brands);
      },
    });

    this.db3DApiBackendClient.getBrandsAvailability(environment.db3dBackendDomain, this.whiteLabelContextService.getCurrentWhiteLabelSlug()).subscribe({
      next: (apiResponse: any) => {
        this.availabilityBrands = createBrandDTO(apiResponse.available_brands);
      },
    });
  }

  public onChangeCheckboxBrandChange(event: Event, brand: Brand): void {
    const checkboxBrand = event.target as HTMLInputElement;
    if (checkboxBrand.checked) {
      this.enabledBrand(brand);
    }else{
      this.disabledBrand(brand);
    }
  }

  public enabledBrand(brand: Brand): void {
    this.visibleBrands = [...this.visibleBrands, brand];
    this.updateVisibleBrands();
  }

  public disabledBrand(brand: Brand): void {
    this.visibleBrands = this.visibleBrands.filter((brandItem: Brand) => brandItem.slug !== brand.slug);
    this.updateVisibleBrands();
  }

  private updateVisibleBrands(): void {

    const payload: VisibleBrandsPayload = {"visible_brands": this.visibleBrands};

    this.db3DApiBackendClient.patchBrandsVisibility(
      environment.db3dBackendDomain,
      this.whiteLabelContextService.getCurrentWhiteLabelSlug(),
      payload).subscribe({
      error: (err: HttpErrorResponse) => {
        if(err.status === 400) 
          this.errorMessage = "Invalid white label specified";
          
        if(err.status === 404) 
          this.errorMessage = "Http failure response";
          
        if(err.status === 500) 
          this.errorMessage = "Internal sever error";
      }
    });
  }

}
