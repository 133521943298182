import { Component, OnInit } from "@angular/core";

@Component({
  selector: "my-models-nav-item-button",
  templateUrl: "./my-models-nav-item-button.component.html",
  styleUrls: ["./my-models-nav-item-button.component.scss"],
})
export class MyModelsNavItemButtonComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
